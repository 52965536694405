@charset "UTF-8";
/* import all utilities scss */
/*
 * @ Variables
 *
*/
/*
 *  Farah Typograpy
*/
/*
 *  Farah base Font sizex
*/
/*
 * H1
*/
/* Russian */
/*Chinese*/
/*
 * H2
*/
/* Russian */
/*Chinese*/
/*
 * H3
*/
/* Russian */
/*Chinese*/
/*
/*
 * H4
*/
/*Russian*/
/*Chinese*/
/*
 * H5
*/
/* Russian */
/*Chinese*/
/*
 * H6
*/
/* Russian */
/*Chinese*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 *  Base Container
*/
/*
 *  Z-index Of components
*/
/* Please do not give z-index more than these z-Index */
/*
 *  Farah desktop font Sizes
*/
/*
 *  Farah mobile font Sizes
*/
/*
 *  Radius
*/
/*
 *  Use Defaults
*/
/*
 *  Base Alignments
*/
/*Hero Map component*/
/*input range*/
/**
    New Summary Cart
*/
/*
 * New Design System - 2025
 *
*/
/* Colors */
/* footer */
/* Editoiral Grid */
/*Carousel with Preview*/
/*
*  My profile
*/
/*
 *  Generic Colors
*/
/*
 *  Farah Base primary colors
*/
/*
 *  Farah Supporting colors
*/
/*
 *  Yas WaterWorld Base primary colors
*/
/*
 *  Yas Island Base primary colors
*/
/*
 *  Yas Ferrari World Base primary colors
*/
/*
 * Farah Font/buttons Color
*/
/*
 * Farah Font/buttons Color
*/
/**
Hotel Booking
*/
/**
tab with placeholder
*/
/**
tab feature list
*/
/*media carousel overlay*/
/*calendar dropdown*/
/* Trip Advisor Color */
/*overlay*/
/* Flash-Sale */
/*
* form placeholder color variables
*/
/*
 * @ Mixins
 *
*/
/*
* Background Image
*/
/*
* Configure vendor
*/
/*
* Line height font
*/
/*
* Clearfix
*/
/*
* Text Nowrap
*/
/*
* Font Smoothing
*/
/*
* Transition
  usage example
    a{
    color: gray;
    @include transition(color .3s ease);
    &:hover {
      color: black;
    }
}
*/
/*
* Background Gradient
*/
/*
* Background Horizontal
*/
/*
* Background Radial
*/
/*
* Background Size
*/
/*
* Background Color Opacity
*/
/*
* Border Radius
*/
/*
* Border Radius Separate
*/
/*
* Box
*/
/*
* Box Rgba
*/
/*
* Box Shadow
*/
/*
* Box Sizing
*/
/*
* Columns
*/
/*
* Flex
*/
/*
* Flip
*/
/*
* Opacity
*/
/*
* Resize
*/
/*
* Rotate
*/
/*
* Text Shadow
*/
/*
* Transform
*/
/*
* Keyframes
 Usage Example
  @include keyframes(slide-down) {
    0% { opacity: 1; }
    90% { opacity: 0; }
  }
*/
/*
* Animation
Usage Example
 .element {
    @include animation('slide-down 5s 3');
  }
*/
/*
* Widescreen
*/
/*
* Desktop (PC's)
*/
/*
* Desktop (PC's)
*/
/*
* Wide screen Desktop (PC's)
*/
/*
  * large screen Desktop (PC's)
  */
/*
* Tablet
*/
/*
  * TabletOnly
  */
/*
* Mobile
*/
/*
* upto Tablet
*/
/*
* No Screen
*/
/**
**  how to Use Mixin for tablet-landscape
**
.example{
  left: 35px;
  @include tablet-landscape{
      left: 100px;
  }
}
**/
/**
*   Use Arrow inside the :after and :before pseudo elements
*   and change the position as per needed
*   @include arrows('right', 7px, 7px, #000);
**/
/*
* Font Face
*/
/*
* Placeholder Styling
*/
/*half circle left right for ticket tiles*/
/**
* Desc: Create equilateral triangle used in header on hover and focus
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * @ Text
 *
*/
/* lang ar */
/* lang ru */
/**
* Heading 5 has two versions
* Bold and Black
* Use heading-ru-5-bold and heading-ru-5-black
**/
/**
* Heading 6 has two versions
* Bold and Black
* Use heading-ru-6-bold and heading-ru-6-black
**/
/* lang zh */
/**
* Heading 5 has two versions
* Bold and Black
* Use heading-zh-5-bold and heading-zh-5-black
**/
/**
* Heading 6 has two versions
* Bold and Black
* Use heading-zh-6-bold and heading-zh-6-black
**/
/* lang all */
:root {
  --font-weight-base: 400;
}

body.lang-ar {
  --font-weight-base: 700;
}

/*
 * @ Gradients
 *
*/
/*
 * Line height font
 */
.c-header {
  position: relative;
  background: #333;
  height: 65px;
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  /*
    * Auto Complete Section in Header Input Box Styling
    */
}
.c-header.ticket-upgrade-header {
  min-height: 81px;
}
.c-header.component {
  max-width: 1366px;
  display: block;
  margin: 0 auto;
}
@media only screen and (min-width: 1024px) {
  .c-header {
    height: 85px;
    padding: 13px 33px 12px 31px;
  }
}
@media only screen and (min-width: 1366px) {
  .c-header {
    height: 105px;
    padding: 15px 33px 14px 37px;
  }
}
.c-header .nav-item-search .overlay-content-container {
  overflow: visible;
}
.c-header .nav-item-search .overlay-content-container .overlay-content-inner-container {
  overflow: visible;
}
.lang-ar .c-header.header-logged .c-header-top-icon.profile:after {
  left: -6px;
  right: auto;
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .lang-ar .c-header.header-logged .c-header-top-icon.profile:after {
      left: -4px;
    }
  }
}
.c-header.header-logged .c-header-top-icon.profile:after {
  /* Medium devices (desktops, 1024px and up) */
  position: absolute;
  content: "";
  border: 2px solid #ffdf00;
  right: 0;
  top: 7px;
  width: 5px;
  height: 5px;
  border-left: 0;
  border-top: 0;
  transform: rotate(45deg);
}
@media only screen and (min-width: 1024px) {
  .c-header.header-logged .c-header-top-icon.profile:after {
    display: none;
  }
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .c-header.header-logged .c-header-top-icon.profile:after {
      right: -4px;
    }
  }
}
.c-header.header-logged .logged-in-icon {
  display: block;
}
.c-header.header-logged .icn-user {
  display: none;
}
.c-header.sticky-header {
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1366px) {
  .c-header.sticky-header {
    position: fixed;
    top: 0;
    min-height: 55px;
    max-height: 55px;
    max-width: 100%;
  }
  .c-header.sticky-header .c-header-top-logo {
    width: 90px;
    margin-top: -25px;
  }
  .c-header.sticky-header .c-header-top-logo img {
    width: 90px;
  }
  .c-header.sticky-header .c-header-wrapper {
    max-width: 1366px;
    margin: 0 auto;
    padding-left: 33px;
  }
  .c-header.sticky-header .header-body {
    top: 0;
    margin-right: 14%;
  }
  .c-header.sticky-header .header-bottom {
    float: right;
    width: auto;
    position: absolute;
    right: 5px;
    top: 7px;
    max-height: 38px;
  }
  .c-header.sticky-header .header-bottom .c-header-top-icon,
  .c-header.sticky-header .header-bottom .opening-hours-dropdown, .c-header.sticky-header .header-bottom-profile, .c-header.sticky-header .header-bottom-lang-switcher {
    display: none;
  }
  .c-header.sticky-header .header-bottom .btn-primary {
    padding-top: 0;
    bottom: 5px;
  }
  .c-header.sticky-header .header-bottom .btn-primary a {
    letter-spacing: 1px;
  }
  .c-header.sticky-header .nav-item-search .overlay-wrapper {
    background: #333;
  }
}
.c-header .c-header-wrapper {
  position: relative;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-header .c-header-wrapper {
    z-index: 2;
  }
}
@media (max-width: 1024px) {
  .c-header .c-header-wrapper {
    background-color: #333;
  }
}
.c-header .c-header-wrapper .btn-subscribe.sticky a {
  border: none;
  border-radius: 0;
  padding: 15px 36px;
  font-size: 16px;
}
.c-header .c-header-wrapper .btn-subscribe a {
  letter-spacing: 1px;
}
.c-header .c-header-wrapper .btn-subscribe a:focus {
  line-height: 1.83;
}
.c-header .c-header-wrapper.hide-on-load .header-bottom {
  display: flex;
  align-items: center;
  flex-direction: revert;
  width: 100%;
  height: 100%;
}
.lang-ar .c-header .c-header-wrapper.hide-on-load .header-bottom {
  height: auto;
}
.c-header .c-header-wrapper.hide-on-load .header-bottom .c-header-top-icon {
  top: 0;
  transform: none;
  right: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.c-header .c-header-wrapper.hide-on-load .header-bottom .c-header-top-icon::before {
  top: 0;
}
.c-header .c-header-wrapper.hide-on-load .header-bottom .c-header-top-icon.profile::before {
  position: relative;
  top: 0;
  transform: none;
  font-size: 14px;
  left: 0;
}
.c-header .c-header-wrapper.hide-on-load .header-bottom .btn-subscribe {
  margin: 0;
}
.c-header .c-header-wrapper.hide-on-load .header-bottom .header-bottom-lang-switcher {
  margin-top: 0;
}
.c-header .c-header-wrapper.hide-on-load .header-bottom .header-bottom-profile {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  overflow: visible;
}
.c-header .c-header-top {
  min-height: 100%;
  display: block;
  position: relative;
  z-index: 2;
  padding: 8px 28px 7px 20px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-header .c-header-top {
    padding: 8px 22px 7px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-header .c-header-top {
    padding: 0;
    border: none;
  }
}
.c-header .c-header-top-logo {
  display: inline-block;
  margin: 0;
  width: auto;
}
.c-header .c-header-top-logo img {
  /* < 1023px */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 320px) and (max-width: 1023px) {
  .c-header .c-header-top-logo img {
    width: 57px;
    height: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .c-header .c-header-top-logo img {
    width: 150px;
    height: 60px;
  }
}
@media only screen and (min-width: 1366px) {
  .c-header .c-header-top-logo img {
    width: 190px;
    height: auto;
  }
}
.c-header .c-header-top-logo a:focus {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header .c-header-top-logo a:focus {
    color: #fff;
  }
}
.c-header .c-header-top-icon {
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 35px;
  height: 21px;
  transform: translate(0, -50%);
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-header .c-header-top-icon {
    width: 29px;
    height: 25px;
  }
}
.c-header .c-header-top-icon.hamburger {
  background-image: url("../../../assets/img/icon-menu-white.svg");
  right: 16px;
  border-radius: 0;
}
.c-header .c-header-top-icon.hamburger img {
  display: none;
}
.c-header .c-header-top-icon.close {
  right: 20px;
}
.c-header .c-header-top .profile {
  right: 74px;
  background: transparent;
}
.c-header .c-header-top .whatsapp {
  display: inline-block;
  margin-top: 3px;
  right: 114px;
  height: 26px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header .c-header-top .whatsapp {
    display: none;
  }
}
.c-header .c-header-top .close {
  border: 0;
}
.c-header.header-logged .header-body {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header.header-logged .header-body {
    display: block;
    width: 100%;
  }
}
.c-header.header-logged .header-bottom {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header.header-logged .header-bottom {
    width: 45%;
  }
}
.c-header.header-logged .header-bottom-btn-logout a:focus {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header.header-logged .header-bottom-btn-logout a:focus {
    color: #333333;
  }
}
.c-header .header-bottom .header-bottom-lang-switcher {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header .header-bottom .header-bottom-lang-switcher {
    margin-top: 8px;
  }
}
.c-header .header-bottom .header-bottom-lang-switcher .dropdown-menu .dropdown-item div:focus {
  color: #999;
}
.c-header.header-pre-logged .header-body {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header.header-pre-logged .header-body {
    display: block;
    width: 100%;
  }
}
.c-header.header-pre-logged .header-bottom {
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header.header-pre-logged .header-bottom {
    width: 56%;
  }
}
@media only screen and (min-width: 1366px) {
  .c-header.header-pre-logged .header-bottom {
    width: 36%;
  }
}
.c-header .nav-item-search {
  top: 4px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header .nav-item-search {
    height: max-content;
    background: transparent;
    top: -4px;
    margin-left: auto;
    width: 48.1818181818%;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    position: absolute;
  }
  body:not(.lang-ar) .c-header .nav-item-search {
    margin-left: 25.9090909091%;
  }
  body.lang-ar .c-header .nav-item-search {
    margin-right: 25.9090909091%;
  }
}
.c-header .nav-item-search .overlay-content-inner-container {
  overflow-y: hidden;
}
.c-header .nav-item-search .overlay-content-container {
  overflow: visible;
}
.c-header .nav-item-search .overlay-wrapper {
  /* Medium devices (desktops, 1024px and up) */
}
.c-header .nav-item-search .overlay-wrapper .top-border:before, .c-header .nav-item-search .overlay-wrapper .top-border:after,
.c-header .nav-item-search .overlay-wrapper .top-border .circle {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .c-header .nav-item-search .overlay-wrapper {
    padding: 0;
    border: 1px solid #7e7e7e;
    border-radius: 4px;
    background: transparent;
    color: #fff;
    width: 81.5151515152%;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    max-width: 600px;
    min-height: 1px;
  }
}
.c-header .nav-item-search .overlay--close-button {
  top: 12px;
  right: 12px;
  padding-left: 12px;
  border-left: 1px solid #fff;
  border-radius: 0;
  z-index: 1;
  /* Medium devices (desktops, 1024px and up) */
}
.c-header .nav-item-search .overlay--close-button svg {
  height: 14px;
  width: 14px;
}
@media only screen and (min-width: 1024px) {
  .c-header .nav-item-search .overlay--close-button {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -khtml-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
.c-header .nav-item-search .autocomplete-wrapper {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header .nav-item-search .autocomplete-wrapper {
    padding: 0;
    padding-left: 2px;
  }
  .lang-ar .c-header .nav-item-search .autocomplete-wrapper {
    padding-right: 2px;
    padding-left: 0;
  }
}
.c-header .nav-item-search .autocomplete-wrapper .input-placeholder {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header .nav-item-search .autocomplete-wrapper .input-placeholder {
    display: none;
  }
}
.c-header .nav-item-search .autocomplete-wrapper .input-container {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header .nav-item-search .autocomplete-wrapper .input-container {
    background: transparent;
    padding: 0;
  }
}
.c-header .nav-item-search .autocomplete-wrapper .form-input {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header .nav-item-search .autocomplete-wrapper .form-input {
    color: #fff;
    caret-color: #fff;
    border: 0;
  }
}
.c-header .nav-item-search .autocomplete {
  opacity: 1;
}
.c-header .nav-item-search .autocomplete::-webkit-input-placeholder {
  color: #fff;
  opacity: 1;
}
.c-header .nav-item-search .autocomplete:-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.c-header .nav-item-search .autocomplete::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.c-header .nav-item-search .autocomplete:-ms-input-placeholder {
  color: #fff;
  opacity: 1;
}
.c-header .nav-item-search .autocomplete::placeholder {
  color: #fff;
  opacity: 1;
}
.c-header .dropdown-item[data-value=AR] {
  letter-spacing: 0;
  font-weight: bold;
  font-family: Noto Kufi Arabic, sans-serif !important;
}
.c-header .autocomplete-wrapper {
  margin: 5px 0px;
  padding: 0 20px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header .autocomplete-wrapper {
    margin: 5px 0px;
    padding: 0;
  }
}
.c-header .autocomplete-wrapper .input-container {
  background: none;
  border-bottom: 1px solid #635c5c;
  padding: 5px 0 20px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header .autocomplete-wrapper .input-container {
    padding: 5px;
    border-bottom: none;
  }
}
.c-header .autocomplete-wrapper .input-container .input-placeholder {
  display: none;
}
.c-header .autocomplete-wrapper .input-container .form-input {
  caret-color: #fff;
  color: #fff;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.71;
  letter-spacing: 0.1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  opacity: 1;
  /* < 768px */
  /* Medium devices (desktops, 1024px and up) */
  font-size: 16px;
}
@media (min-width: 768px) {
  .c-header .autocomplete-wrapper .input-container .form-input {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-header .autocomplete-wrapper .input-container .form-input {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .c-header .autocomplete-wrapper .input-container .form-input {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.c-header .autocomplete-wrapper .input-container .form-input::-webkit-input-placeholder {
  color: #fff;
  opacity: 1;
}
.c-header .autocomplete-wrapper .input-container .form-input:-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.c-header .autocomplete-wrapper .input-container .form-input::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.c-header .autocomplete-wrapper .input-container .form-input:-ms-input-placeholder {
  color: #fff;
  opacity: 1;
}
.c-header .autocomplete-wrapper .input-container .form-input::placeholder {
  color: #fff;
  opacity: 1;
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header .autocomplete-wrapper .input-container .form-input {
    width: 100%;
    margin: 0;
  }
  .c-header .autocomplete-wrapper .input-container .form-input:hover, .c-header .autocomplete-wrapper .input-container .form-input:active, .c-header .autocomplete-wrapper .input-container .form-input:focus {
    background-color: #333;
  }
}
@media only screen and (min-width: 1024px) {
  .c-header .autocomplete-wrapper .input-container .form-input {
    padding-left: 50px;
  }
}
.c-header .autocomplete-wrapper .input-container .btn-reset {
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header .autocomplete-wrapper .input-container .btn-reset {
    display: block;
    padding: 0 6px;
    position: absolute;
    right: 0;
    background-image: url(../../../assets/img/icon-menu-yellow.png);
    background-repeat: no-repeat;
    background-size: 14px;
    width: 26px;
    height: 26px;
    background-position: center;
  }
  .c-header .autocomplete-wrapper .input-container .btn-reset.visibility-hidden {
    display: none;
  }
  .c-header .autocomplete-wrapper .input-container .btn-reset.visibility-hidden ~ .btn-submit {
    display: block;
    z-index: 0;
  }
  .c-header .autocomplete-wrapper .input-container .btn-reset ~ .btn-submit {
    display: none;
  }
}
.c-header .autocomplete-wrapper .input-container .btn-submit {
  border: 0;
  color: #fff;
  padding: 0;
  width: 21px;
  height: 21px;
  opacity: 1;
  /* < 768px */
  /* Medium devices (desktops, 1024px and up) */
}
.c-header .autocomplete-wrapper .input-container .btn-submit:hover, .c-header .autocomplete-wrapper .input-container .btn-submit:focus {
  background: none;
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header .autocomplete-wrapper .input-container .btn-submit {
    position: absolute;
    right: 24px;
    z-index: -1;
  }
}
@media only screen and (min-width: 1024px) {
  .c-header .autocomplete-wrapper .input-container .btn-submit {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 40px;
    background: transparent;
    position: relative;
  }
}
.c-header .autocomplete-wrapper .input-container .btn-submit svg {
  height: 100%;
  width: 100%;
}
.c-header .results-container {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header .results-container {
    height: auto;
    margin-top: 12px;
    margin-left: 0;
  }
}
.c-header .results-container .autosuggest-list {
  max-height: none;
  font-size: 16px;
  font-size: 1rem;
  padding: 30px 20px 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header .results-container .autosuggest-list {
    max-height: 288px;
    padding: 49px 50px 23px 50px;
  }
}
.c-header .results-container .autosuggest-list li {
  margin-bottom: 23px;
  letter-spacing: 0.1;
}
.c-header .results-container .language-switcher .dropdown-menu {
  width: 240px;
  top: 30px;
}
.c-header .results-container .language-switcher .dropdown-item {
  padding: 6px 18px;
}
.c-header .results-container .language-switcher .dropdown-item span {
  font-weight: 700;
  padding-top: 0;
}
.c-header .results-container .language-switcher .dropdown-item > div span {
  vertical-align: middle;
}
.c-header .results-container .language-switcher .dropdown-item > div img {
  width: auto;
  max-height: 22px;
}
.c-header .hide-on-load .header-bottom {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header .hide-on-load .header-bottom {
    display: block;
  }
}
.c-header .header-bottom-lang-switcher .language-switcher .dropdown-component .dropdown-menu li a[lang=EN] {
  font-family: "GothamMedium", "Open Sans", Arial, sans-serif !important;
  font-weight: 500;
}

.opening-hours-dropdown {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .opening-hours-dropdown {
    height: 45px;
  }
}
.opening-hours-dropdown .opening-hours-container {
  color: #fff;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .opening-hours-dropdown .opening-hours-container {
    margin-bottom: 0;
  }
}
.opening-hours-dropdown .opening-hours-container .opening-hours-header {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1023px) {
  .opening-hours-dropdown .opening-hours-container .opening-hours-header {
    margin-top: 4px;
  }
}
.opening-hours-dropdown .opening-hours-container .opening-hours-header h4 {
  font-size: 14px;
}
.opening-hours-dropdown .opening-hours-container .opening-hours-details {
  line-height: 22px;
}
.opening-hours-dropdown .opening-hours-container .opening-hours-details h4 {
  letter-spacing: 0.1px;
  margin-bottom: 8px;
}
.opening-hours-dropdown .opening-hours-container .opening-hours-details .view-more {
  font-weight: 700;
  text-decoration: none;
  margin-top: 10px;
  color: #fff;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .opening-hours-dropdown .opening-hours-container .opening-hours-details .view-more {
    font-size: 14px;
  }
}
.opening-hours-dropdown .opening-hours-container .opening-hours-details .view-more::before {
  content: "";
}
.opening-hours-dropdown.open .opening-hours-container {
  background: #333;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .opening-hours-dropdown.open .opening-hours-container {
    padding: 12px 20px 20px 20px;
  }
  .lang-ar .opening-hours-dropdown.open .opening-hours-container {
    padding-top: 12px;
  }
}
.opening-hours-dropdown:not(.open) .opening-hours-container {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .opening-hours-dropdown:not(.open) .opening-hours-container {
    padding: 12px 20px;
  }
}

/* < 1023px */
@media (min-width: 320px) and (max-width: 1023px) {
  .tenant-wbw .custom-mobile-header {
    position: sticky;
    top: -1px;
  }
  .tenant-wbw .custom-mobile-header .c-header {
    height: auto;
  }
  .tenant-wbw .custom-mobile-header .c-header .c-header-wrapper .c-header-top {
    padding: 20px;
  }
  .tenant-wbw .custom-mobile-header .c-header .c-header-wrapper .c-header-top .c-header-top-icon.hamburger {
    background-image: none;
  }
  .tenant-wbw .custom-mobile-header .c-header .c-header-wrapper .c-header-top .c-header-top-logo {
    background: #333;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    top: 0;
    transition: all 0.5s;
  }
  .tenant-wbw .custom-mobile-header .c-header .c-header-wrapper .c-header-top .c-header-top-logo img {
    width: 57px;
    height: auto;
  }
  .tenant-wbw .custom-mobile-header .c-header .c-header-wrapper .c-header-top .btn-subscribe a {
    letter-spacing: 1px;
    color: #333;
  }
  .lang-en .tenant-wbw .custom-mobile-header .c-header .c-header-wrapper .c-header-top .btn-subscribe a {
    font-weight: 400;
    letter-spacing: 1px;
  }
  .tenant-wbw .custom-mobile-header .c-header .c-header-wrapper .c-header-top.min-height-mobile {
    min-height: 77px;
  }
  .tenant-wbw .custom-mobile-header .c-header .c-header-wrapper.profile-open .header-body-profile {
    margin: 0 37px;
  }
  .tenant-wbw .custom-mobile-header .c-header .c-header-wrapper.profile-open .header-body-profile .header-body-profile-name {
    text-align: left;
  }
  .lang-ar .tenant-wbw .custom-mobile-header .c-header .c-header-wrapper.profile-open .header-body-profile .header-body-profile-name {
    text-align: right;
  }
  .tenant-wbw .custom-mobile-header .c-header .c-header-wrapper.profile-open .header-body-profile ul {
    text-align: left;
    margin-left: 0;
    margin-right: 0;
  }
  .lang-ar .tenant-wbw .custom-mobile-header .c-header .c-header-wrapper.profile-open .header-body-profile ul {
    text-align: right;
  }
  .tenant-wbw .custom-mobile-header .c-header .c-header-wrapper.profile-open .header-bottom-btn-logout {
    margin: 0 37px;
    width: auto;
    text-align: left;
  }
  .tenant-wbw .custom-mobile-header .c-header .c-header-wrapper .header-body {
    background: #333;
  }
  .tenant-wbw .custom-mobile-header .c-header .c-header-wrapper .header-body .nav .nav-parent-title .header-nav-link {
    line-height: 22px;
  }
  .lang-ar .tenant-wbw .custom-mobile-header .c-header .c-header-wrapper .header-body .nav .nav-parent-title .header-nav-link {
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 1.5px !important;
  }
  .tenant-wbw .custom-mobile-header .c-header .c-header-wrapper .header-body .nav .meganav-hoverable .meganav .meganav--text-wrapper-list-item {
    line-height: normal;
    padding: 0 0 12px;
  }
  .tenant-wbw .custom-mobile-header .c-header .c-header-wrapper .header-body .nav .meganav-hoverable .meganav .meganav--text-wrapper-list-item .lang-ar {
    line-height: 16px;
  }
  .tenant-wbw .custom-mobile-header .c-header .c-header-wrapper .header-body .nav .meganav-hoverable .meganav .meganav--text-wrapper-list-item a {
    line-height: 22px;
    font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  }
  .lang-ar .tenant-wbw .custom-mobile-header .c-header .c-header-wrapper .header-body .nav .meganav-hoverable .meganav .meganav--text-wrapper-list-item a {
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 1.5px !important;
  }
  .tenant-wbw .custom-mobile-header.sticky-header:not(.mobile-menu-opened) {
    position: sticky;
    top: -1px;
  }
  .tenant-wbw .custom-mobile-header.sticky-header:not(.mobile-menu-opened) .c-header .c-header-wrapper .c-header-top {
    padding: 10px 20px;
  }
  .tenant-wbw .custom-mobile-header.sticky-header:not(.mobile-menu-opened) .c-header .c-header-wrapper .c-header-top .c-header-top-logo {
    padding: 5px 10px 12px;
  }
}
#hamburger-cross-icon span {
  height: 2.4px;
}

.header-email-verification-parent {
  background: #000 !important;
  /* < 768px */
}
.header-email-verification-parent .hamburger {
  pointer-events: none !important;
  cursor: default !important;
}
.header-email-verification-parent .header-bottom,
.header-email-verification-parent .header-body,
.header-email-verification-parent .c-header-top-icon,
.header-email-verification-parent .c-header-top-icon {
  display: none !important;
}
.header-email-verification-parent .c-header-top-logo {
  position: relative !important;
  background-color: #000 !important;
}
.header-email-verification-parent .c-header {
  background: #000 !important;
}
.header-email-verification-parent .c-header-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.header-email-verification-parent .c-header-top {
  display: flex !important;
  background-color: #000 !important;
}
@media (min-width: 320px) and (max-width: 767px) {
  .header-email-verification-parent .dark-logo {
    background: #000 !important;
  }
  .header-email-verification-parent .c-header-top {
    padding: 4px !important;
  }
}

.mypayment--page .c-header-top-icon-wrap, .renewpasspayment--page .c-header-top-icon-wrap {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1023px) {
  .mypayment--page .c-header-top-icon-wrap, .renewpasspayment--page .c-header-top-icon-wrap {
    display: none;
  }
}
.mypayment--page .c-header-wrapper .c-header-top .c-header-top-icon.cart span,
.mypayment--page .c-header-wrapper .c-header-top .c-header-top-icon.cart svg,
.mypayment--page .c-header-wrapper .c-header-top .c-header-top-icon.cart p, .renewpasspayment--page .c-header-wrapper .c-header-top .c-header-top-icon.cart span,
.renewpasspayment--page .c-header-wrapper .c-header-top .c-header-top-icon.cart svg,
.renewpasspayment--page .c-header-wrapper .c-header-top .c-header-top-icon.cart p {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1023px) {
  .mypayment--page .c-header-wrapper .c-header-top .c-header-top-icon.cart span,
  .mypayment--page .c-header-wrapper .c-header-top .c-header-top-icon.cart svg,
  .mypayment--page .c-header-wrapper .c-header-top .c-header-top-icon.cart p, .renewpasspayment--page .c-header-wrapper .c-header-top .c-header-top-icon.cart span,
  .renewpasspayment--page .c-header-wrapper .c-header-top .c-header-top-icon.cart svg,
  .renewpasspayment--page .c-header-wrapper .c-header-top .c-header-top-icon.cart p {
    display: none;
  }
}

.custom-mini-header .c-mini-header {
  background: #333;
}
.custom-mini-header .c-mini-header .container {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .custom-mini-header .c-mini-header .container {
    padding: 0 33px;
    max-width: 1366px;
  }
  .lang-ar .custom-mini-header .c-mini-header .container {
    padding: 0 33px;
  }
}
.custom-mini-header .c-mini-header .container .right-container {
  align-items: center;
}
.lang-ar .custom-mini-header .c-mini-header .container .right-container .header-bottom-lang-switcher {
  right: auto;
  margin-left: 0px;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .lang-ar .custom-mini-header .c-mini-header .container .right-container .header-bottom-lang-switcher {
    left: 75px;
  }
}
.custom-mini-header .c-mini-header .container .right-container .header-bottom-lang-switcher .language-switcher .dropdown-component .dropdown-menu::before {
  background: #f6f6f6;
}
.custom-mini-header .c-mini-header .container .right-container .header-bottom-lang-switcher .language-switcher .dropdown-component .dropdown-menu .dropdown-item {
  padding: 16px 18px;
  background: #f6f6f6;
}
.custom-mini-header .c-mini-header .container .right-container .header-bottom-lang-switcher .language-switcher .dropdown-component .dropdown-menu .dropdown-item a svg {
  width: 12px;
  height: 12px;
}
.custom-mini-header .c-mini-header .container .right-container .header-bottom-lang-switcher .language-switcher .dropdown-component .dropdown-menu .dropdown-item:last-child {
  border-top: 1px solid #d3d0d0;
}
.custom-mini-header .c-mini-header .container .right-container .header-bottom-lang-switcher .language-switcher .dropdown-component .dropdown-menu .dropdown-item[data-value=EN] a {
  font-family: "GothamMedium", "Open Sans", Arial, sans-serif !important;
  font-weight: 500;
  letter-spacing: 1px !important;
  line-height: 25.6px !important;
}
.custom-mini-header .c-mini-header .container .right-container .header-bottom-lang-switcher .language-switcher .dropdown-component .dropdown-menu .dropdown-item[data-value=AR] a {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  letter-spacing: normal;
}
.custom-mini-header .c-mini-header .container .right-container .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option {
  border-left: none;
  border-right: none;
  right: auto;
  line-height: 16px;
  font-weight: 700;
}
.custom-mini-header .c-mini-header .container .right-container .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option::after {
  width: 6.36px;
  height: 6.36px;
  top: calc(50% + 1px);
}
.custom-mini-header .c-mini-header .container .right-container .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option.collapse::after {
  top: calc(50% - 2px);
}
.custom-mini-header .c-mini-header .container .right-container .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option span {
  font-weight: 500;
}
.custom-mini-header .c-mini-header .container .right-container .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option span {
  font-family: "GothamBlack", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.8px;
}
.custom-mini-header .c-mini-header .container .right-container .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option.collapse {
  border-left: none;
}
.custom-mini-header .c-mini-header .container .right-container .header-bottom-profile .header-bottom-lang-switcher {
  margin: 0;
}
.custom-mini-header .c-mini-header .container .right-container {
  max-height: 40px;
}
.custom-mini-header .c-mini-header .container .right-container .header-bottom-profile {
  overflow: visible;
}
.custom-mini-header .c-mini-header .container .right-container .header-bottom-profile .header-bottom-profile-tooltip {
  padding: 15px 20px 0;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
  background: #f6f6f6;
  border-radius: 4px;
}
.custom-mini-header .c-mini-header .container .right-container .header-bottom-profile .header-bottom-profile-tooltip::after, .custom-mini-header .c-mini-header .container .right-container .header-bottom-profile .header-bottom-profile-tooltip::before {
  background: #f6f6f6;
}
.custom-mini-header .c-mini-header .container .right-container .header-bottom-profile .header-bottom-profile-tooltip .header-bottom-profile-name,
.custom-mini-header .c-mini-header .container .right-container .header-bottom-profile .header-bottom-profile-tooltip ul li,
.custom-mini-header .c-mini-header .container .right-container .header-bottom-profile .header-bottom-profile-tooltip .header-bottom-btn-logout a {
  background: transparent;
}
.custom-mini-header .c-mini-header .container .right-container .header-bottom-profile .header-bottom-profile-tooltip .header-bottom-profile-name {
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  line-height: 20px;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  /* < 1023px */
}
.lang-ar .custom-mini-header .c-mini-header .container .right-container .header-bottom-profile .header-bottom-profile-tooltip .header-bottom-profile-name {
  font-weight: 700;
}
@media (min-width: 320px) and (max-width: 1023px) {
  .custom-mini-header .c-mini-header .container .right-container .header-bottom-profile .header-bottom-profile-tooltip .header-bottom-profile-name {
    padding: 0;
  }
}
.custom-mini-header .c-mini-header .container .right-container .header-bottom-profile .header-bottom-profile-tooltip ul li {
  padding: 0;
  border-bottom: 1px solid #d3d0d0;
}
.custom-mini-header .c-mini-header .container .right-container .header-bottom-profile .header-bottom-profile-tooltip ul li a,
.custom-mini-header .c-mini-header .container .right-container .header-bottom-profile .header-bottom-profile-tooltip .header-bottom-btn-logout a {
  font-family: "GothamMedium", "Open Sans", Arial, sans-serif;
  padding: 15px 0 !important;
  color: #000;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1023px) {
  .custom-mini-header .c-mini-header .container .right-container .header-bottom-profile .header-bottom-profile-tooltip ul li a,
  .custom-mini-header .c-mini-header .container .right-container .header-bottom-profile .header-bottom-profile-tooltip .header-bottom-btn-logout a {
    padding: 0;
    border: 0;
  }
}
.tenant-wbw .custom-mini-header .c-mini-header .container .right-container .header-bottom-profile .header-bottom-profile-tooltip ul li:last-child {
  border: 0;
}
.custom-mini-header .c-mini-header .container .right-container .header-bottom-profile .header-bottom-profile-tooltip .header-bottom-btn-logout {
  padding: 0;
  border-top: 1px solid #d3d0d0;
}
.custom-mini-header .c-mini-header .container .right-container .header-bottom-profile .header-bottom-btn-logout {
  margin: 0;
  text-align: left;
  border-top: 0.5px solid #dedede;
}
.lang-ar .custom-mini-header .c-mini-header .container .right-container .header-bottom-profile .header-bottom-btn-logout {
  text-align: right;
}

.tenant-wbw .ticket-section {
  margin-top: 0 !important;
  padding-bottom: 0 !important;
}
.tenant-wbw .ticket-section .product-details-wrapper .product-details {
  padding-bottom: 0px;
}
.tenant-wbw .ticket-section .product-details-wrapper .product-details .product-name .date-value span {
  font-size: 14px !important;
}
.tenant-wbw .ticket-section .product-details-wrapper .ticket-subsection-container {
  display: flex !important;
  gap: 5px;
  padding: 15px 0px 20px 0px;
  margin: 0 !important;
  flex-direction: column;
}
.tenant-wbw .ticket-section .product-details-wrapper .ticket-subsection-container .ticket-sub-section, .tenant-wbw .ticket-section .product-details-wrapper .ticket-subsection-container .ticket-info {
  padding-bottom: 0 !important;
}
.tenant-wbw .ticket-section .product-details-wrapper .ticket-subsection-container .ticket-sub-section .delete-btn, .tenant-wbw .ticket-section .product-details-wrapper .ticket-subsection-container .ticket-info .delete-btn {
  margin-left: 20px;
  margin-right: -4px;
  margin-top: 2px;
}
html[lang=ar-AE] .tenant-wbw .ticket-section .product-details-wrapper .ticket-subsection-container .ticket-sub-section .delete-btn, html[lang=ar-AE] .tenant-wbw .ticket-section .product-details-wrapper .ticket-subsection-container .ticket-info .delete-btn {
  margin-left: 0;
  margin-top: 6px;
  margin-right: 0px;
}
.tenant-wbw .cart-summary-container .cart-summary .cart-summary-details .cart-price-container .total-price-container {
  display: flex;
  flex-direction: column;
}
.tenant-wbw .cart-summary-container .cart-summary .cart-summary-details .cart-price-container .total-price-container .coupon-discount-container {
  order: 3;
}
.tenant-wbw .cart-summary-container .cart-summary .cart-summary-details .cart-price-container .total-price-container .currency-price-container {
  order: 4;
}

.tenant-wbw .ticket-section {
  margin-top: 0 !important;
  padding-bottom: 0 !important;
}
.tenant-wbw .ticket-section .product-details-wrapper .product-details .product-name .date-value {
  margin-top: -5px;
}
.tenant-wbw .ticket-section .product-details-wrapper .product-details .product-name .date-value span {
  font-size: 14px !important;
}
.tenant-wbw .ticket-section .product-details-wrapper .product-details .ticket-validity .valid-date {
  position: relative;
  top: 0px;
}
.tenant-wbw .ticket-section .product-details-wrapper .ticket-subsection-container {
  display: flex;
  gap: 15px;
}
.tenant-wbw .ticket-section .product-details-wrapper .ticket-subsection-container .ticket-sub-section .ticket-info {
  padding: 4px 0 !important;
}
.tenant-wbw .cart-summary-container .cart-summary .cart-summary-details .cart-price-container .total-price-container {
  display: flex;
  flex-direction: column;
}
.tenant-wbw .cart-summary-container .cart-summary .cart-summary-details .cart-price-container .total-price-container .coupon-discount-container {
  order: 3;
}
.tenant-wbw .cart-summary-container .cart-summary .cart-summary-details .cart-price-container .total-price-container .currency-price-container {
  order: 4;
}

.mypayment--page .custom-mini-header .c-mini-header .container .right-container .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option span, .renewpasspayment--page .custom-mini-header .c-mini-header .container .right-container .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option span {
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-weight: 500;
}
.mypayment--page .custom-mini-header .c-mini-header .container .right-container .c-header-top-icon, .renewpasspayment--page .custom-mini-header .c-mini-header .container .right-container .c-header-top-icon {
  min-width: 18px;
  width: 18px;
  height: 18px;
  padding: 0px;
  margin-right: 0;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .mypayment--page .custom-mini-header .c-mini-header .container .right-container .c-header-top-icon, .renewpasspayment--page .custom-mini-header .c-mini-header .container .right-container .c-header-top-icon {
    width: 25px;
    height: 25px;
  }
  html[lang=ar-AE] .mypayment--page .custom-mini-header .c-mini-header .container .right-container .c-header-top-icon, html[lang=ar-AE] .renewpasspayment--page .custom-mini-header .c-mini-header .container .right-container .c-header-top-icon {
    margin-right: 24px;
  }
}
.mypayment--page .custom-mini-header .c-mini-header .container .left-container .redirect-btn span, .renewpasspayment--page .custom-mini-header .c-mini-header .container .left-container .redirect-btn span {
  padding-left: 14px;
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-weight: 500;
}
.mypayment--page .page-container .container, .renewpasspayment--page .page-container .container {
  margin-bottom: 8px !important;
}
.mypayment--page .page-container .container .row .col-lg-4.col-md-12.col-sm-12 .cart-summary-editable .c-my-cart-b2c-container .c-b2c-cart .c-b2c-cart-wrapper, .renewpasspayment--page .page-container .container .row .col-lg-4.col-md-12.col-sm-12 .cart-summary-editable .c-my-cart-b2c-container .c-b2c-cart .c-b2c-cart-wrapper {
  margin: 40px 0px 0 0;
}
.mypayment--page .page-container .container .row .col-lg-4.col-md-12.col-sm-12 .c-b2c-cart-header-cart-summnary .c-b2c-cart-header, .renewpasspayment--page .page-container .container .row .col-lg-4.col-md-12.col-sm-12 .c-b2c-cart-header-cart-summnary .c-b2c-cart-header {
  padding: 18px 20px 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mypayment--page .page-container .container .row .col-lg-4.col-md-12.col-sm-12 .c-b2c-cart-header-cart-summnary .c-b2c-cart-header .c-b2c-cart-header-container .grid-check-icon, .renewpasspayment--page .page-container .container .row .col-lg-4.col-md-12.col-sm-12 .c-b2c-cart-header-cart-summnary .c-b2c-cart-header .c-b2c-cart-header-container .grid-check-icon {
  margin-top: 0px;
}
.mypayment--page .page-container .container .row .col-lg-4.col-md-12.col-sm-12 .c-b2c-cart-header-cart-summnary .c-b2c-cart-header .c-b2c-cart-header-container .grid-check-icon-cart-summary .chevronDown span, .renewpasspayment--page .page-container .container .row .col-lg-4.col-md-12.col-sm-12 .c-b2c-cart-header-cart-summnary .c-b2c-cart-header .c-b2c-cart-header-container .grid-check-icon-cart-summary .chevronDown span {
  left: 50%;
  width: 10px;
  height: 10px;
}
.mypayment--page .page-container .container .row .col-lg-4.col-md-12.col-sm-12 .c-b2c-cart-header-cart-summnary .c-b2c-cart-header .c-b2c-cart-header-container .grid-check-icon-cart-summary .chevronDown span.open, .renewpasspayment--page .page-container .container .row .col-lg-4.col-md-12.col-sm-12 .c-b2c-cart-header-cart-summnary .c-b2c-cart-header .c-b2c-cart-header-container .grid-check-icon-cart-summary .chevronDown span.open {
  top: 8px;
}
.mypayment--page .page-container .container .row .col-lg-4.col-md-12.col-sm-12 .c-b2c-cart-header-cart-summnary .c-b2c-cart-header .heading-4.cart-title, .renewpasspayment--page .page-container .container .row .col-lg-4.col-md-12.col-sm-12 .c-b2c-cart-header-cart-summnary .c-b2c-cart-header .heading-4.cart-title {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .mypayment--page .page-container .container .row .col-lg-4.col-md-12.col-sm-12 .c-b2c-cart-header-cart-summnary .c-b2c-cart-header .heading-4.cart-title, .renewpasspayment--page .page-container .container .row .col-lg-4.col-md-12.col-sm-12 .c-b2c-cart-header-cart-summnary .c-b2c-cart-header .heading-4.cart-title {
    margin: 0 auto;
    flex: none;
    padding-left: 20px;
  }
  html[lang=ar-AE] .mypayment--page .page-container .container .row .col-lg-4.col-md-12.col-sm-12 .c-b2c-cart-header-cart-summnary .c-b2c-cart-header .heading-4.cart-title, html[lang=ar-AE] .renewpasspayment--page .page-container .container .row .col-lg-4.col-md-12.col-sm-12 .c-b2c-cart-header-cart-summnary .c-b2c-cart-header .heading-4.cart-title {
    text-align: center;
    flex: auto;
    padding-left: 0px;
  }
}
.mypayment--page .page-container .container .row .c-payment .c-payment-wrapper .heading-3.sub-title, .renewpasspayment--page .page-container .container .row .c-payment .c-payment-wrapper .heading-3.sub-title {
  margin-bottom: 20px;
}
.mypayment--page .page-container .container .row .c-payment .c-payment-wrapper .payment-container-wrapper, .renewpasspayment--page .page-container .container .row .c-payment .c-payment-wrapper .payment-container-wrapper {
  width: 99%;
  gap: 59px;
}
.mypayment--page .page-container .container .row .c-payment .c-payment-wrapper .payment-container-wrapper .c-payment-paymentMode, .renewpasspayment--page .page-container .container .row .c-payment .c-payment-wrapper .payment-container-wrapper .c-payment-paymentMode {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .mypayment--page .page-container .container .row .c-payment .c-payment-wrapper .payment-container-wrapper .c-payment-paymentMode, .renewpasspayment--page .page-container .container .row .c-payment .c-payment-wrapper .payment-container-wrapper .c-payment-paymentMode {
    width: 292px;
  }
}
.mypayment--page .page-container .container .row .c-payment .c-payment-wrapper .payment-container-wrapper .c-payment-paymentMode.payment-types-variation.payment-radio-btn-variation ul li .c-payfort.card-detail, .renewpasspayment--page .page-container .container .row .c-payment .c-payment-wrapper .payment-container-wrapper .c-payment-paymentMode.payment-types-variation.payment-radio-btn-variation ul li .c-payfort.card-detail {
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .mypayment--page .page-container .container .row .c-payment .c-payment-wrapper .payment-container-wrapper .c-payment-paymentMode.payment-types-variation.payment-radio-btn-variation ul li .c-payfort.card-detail, .renewpasspayment--page .page-container .container .row .c-payment .c-payment-wrapper .payment-container-wrapper .c-payment-paymentMode.payment-types-variation.payment-radio-btn-variation ul li .c-payfort.card-detail {
    margin: 16px 0 0;
  }
}
.mypayment--page .page-container .container .row .c-payment .c-payment-wrapper .payment-container-wrapper .c-payment-paymentMode.payment-types-variation.payment-radio-btn-variation ul li .payment-items-container-label, .renewpasspayment--page .page-container .container .row .c-payment .c-payment-wrapper .payment-container-wrapper .c-payment-paymentMode.payment-types-variation.payment-radio-btn-variation ul li .payment-items-container-label {
  width: 100%;
}
.mypayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .form-element, .renewpasspayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .form-element {
  min-width: 45%;
}
.mypayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .form-element .form-label, .renewpasspayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .form-element .form-label {
  top: 50%;
  transform: translate(0%, -50%);
  line-height: 24px;
  padding: 15px 22px;
}
html[lang=ar-AE] .mypayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .form-element .form-label, html[lang=ar-AE] .renewpasspayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .form-element .form-label {
  padding: 15px 0px;
}
.mypayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .input-type-password, .renewpasspayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .input-type-password {
  width: 100%;
}
.mypayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .form-element.active .form-label, .renewpasspayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .form-element.active .form-label {
  top: 20%;
  line-height: 24px;
  padding: 5px 15px 0px 15px;
}
html[lang=ar-AE] .mypayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .form-element.active .form-label, html[lang=ar-AE] .renewpasspayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .form-element.active .form-label {
  padding: 0px 0px;
}
.mypayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .form-element.active .form-label .lang-ar, .renewpasspayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .form-element.active .form-label .lang-ar {
  padding: 0px 0px;
}
.mypayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .checkbox, .renewpasspayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .checkbox {
  border-radius: 0;
}
.mypayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .checkbox::after, .renewpasspayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .checkbox::after {
  border-radius: 0;
}
.mypayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .c-ticket-tooltip, .renewpasspayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .c-ticket-tooltip {
  top: 50%;
  transform: translate(-80%, -45%);
  padding: 0;
}
html[lang=ar-AE] .mypayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .c-ticket-tooltip, html[lang=ar-AE] .renewpasspayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .c-ticket-tooltip {
  transform: translate(30%, -85%);
}
.mypayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .c-ticket-tooltip svg, .renewpasspayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .c-ticket-tooltip svg {
  height: 21px;
  width: 21px;
}
.mypayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .c-payfort-form-v1 #validity, .renewpasspayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .c-payfort-form-v1 #validity {
  background-position-x: calc(100% - 24px);
}
html[lang=ar-AE] .mypayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .c-payfort-form-v1 #validity, html[lang=ar-AE] .renewpasspayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .c-payfort-form-v1 #validity {
  background-position-x: 18px;
  background-position-y: 50%;
}
.mypayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .payfort-submit-btn, .renewpasspayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .payfort-submit-btn {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .mypayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .payfort-submit-btn, .renewpasspayment--page .page-container .container .row .c-payment .c-payment-wrapper .right-section .custom-payfort-form .payfort-submit-btn {
    padding-bottom: 30px !important;
  }
}
.mypayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation.anual-pass-comp .text-center, .renewpasspayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation.anual-pass-comp .text-center {
  grid-column: 1/-1;
  margin-left: 0;
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1023px) {
  .mypayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation.anual-pass-comp .text-center, .renewpasspayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation.anual-pass-comp .text-center {
    margin-top: 0px;
  }
}
.mypayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation.anual-pass-comp .text-center button, .renewpasspayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation.anual-pass-comp .text-center button {
  margin-left: 0 !important;
}
html[lang=ar-AE] .mypayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation.anual-pass-comp .text-center button, html[lang=ar-AE] .renewpasspayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation.anual-pass-comp .text-center button {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  html[lang=ar-AE] .mypayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation.anual-pass-comp .text-center button, html[lang=ar-AE] .renewpasspayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation.anual-pass-comp .text-center button {
    margin-right: 10px;
  }
}
.mypayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation.anual-pass-comp .text-center:has(#submit-btn), .renewpasspayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation.anual-pass-comp .text-center:has(#submit-btn) {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1023px) {
  .mypayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation.anual-pass-comp .text-center:has(#submit-btn), .renewpasspayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation.anual-pass-comp .text-center:has(#submit-btn) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.mypayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .c-form .email-control-wrapper-2.v-form-view-checkout, .renewpasspayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .c-form .email-control-wrapper-2.v-form-view-checkout {
  /* Medium devices (desktops, 1024px and up) */
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 1024px) {
  .mypayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .c-form .email-control-wrapper-2.v-form-view-checkout, .renewpasspayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .c-form .email-control-wrapper-2.v-form-view-checkout {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 24px;
  }
}
.mypayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .c-form .email-control-wrapper-2.v-form-view-checkout .form-element, .renewpasspayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .c-form .email-control-wrapper-2.v-form-view-checkout .form-element {
  margin: 0px;
  width: 100%;
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1023px) {
  .mypayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .c-form .email-control-wrapper-2.v-form-view-checkout .form-element, .renewpasspayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .c-form .email-control-wrapper-2.v-form-view-checkout .form-element {
    margin-bottom: 20px !important;
  }
}
.mypayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .c-form .email-control-wrapper-2.v-form-view-checkout .form-element.form-checkbox, .renewpasspayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .c-form .email-control-wrapper-2.v-form-view-checkout .form-element.form-checkbox {
  grid-column: 1/-1;
  margin-left: 0;
  margin-top: 0px;
  padding-left: 0;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .mypayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .c-form .email-control-wrapper-2.v-form-view-checkout .form-element.form-checkbox, .renewpasspayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .c-form .email-control-wrapper-2.v-form-view-checkout .form-element.form-checkbox {
    margin-top: 0;
  }
}
.mypayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .c-form .email-control-wrapper-2.v-form-view-checkout .form-element.form-checkbox .form-label, .renewpasspayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .c-form .email-control-wrapper-2.v-form-view-checkout .form-element.form-checkbox .form-label {
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .mypayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .c-form .email-control-wrapper-2.v-form-view-checkout .form-element.form-checkbox .form-label, .renewpasspayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .c-form .email-control-wrapper-2.v-form-view-checkout .form-element.form-checkbox .form-label {
    translate: none;
    padding: 0 22px;
  }
}
.mypayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .c-form .c-payment-newsletter .body-3, .renewpasspayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .c-form .c-payment-newsletter .body-3 {
  line-height: 18px;
}
.mypayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .guest-checkout-wrap, .renewpasspayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .guest-checkout-wrap {
  max-width: 100%;
}
.mypayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .guest-checkout-wrap .c-form-wrapper, .renewpasspayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .guest-checkout-wrap .c-form-wrapper {
  max-width: 100%;
}
.mypayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .guest-checkout-wrap .c-form-wrapper .form-element .form-label, .renewpasspayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .guest-checkout-wrap .c-form-wrapper .form-element .form-label {
  top: 50%;
  transform: translate(0%, -50%);
  line-height: 24px;
  padding: 15px 22px;
}
html[lang=ar-AE] .mypayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .guest-checkout-wrap .c-form-wrapper .form-element .form-label, html[lang=ar-AE] .renewpasspayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .guest-checkout-wrap .c-form-wrapper .form-element .form-label {
  padding: 15px 0px;
}
.mypayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .guest-checkout-wrap .c-form-wrapper .form-element.active .form-label, .renewpasspayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .guest-checkout-wrap .c-form-wrapper .form-element.active .form-label {
  top: 5px;
  transform: none;
  line-height: 24px;
  padding: 0px 20px;
}
html[lang=ar-AE] .mypayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .guest-checkout-wrap .c-form-wrapper .form-element.active .form-label, html[lang=ar-AE] .renewpasspayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .guest-checkout-wrap .c-form-wrapper .form-element.active .form-label {
  padding: 0px 0px;
}
.mypayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .guest-checkout-wrap .c-form-wrapper .form-element.active .checkbox-label, .renewpasspayment--page .page-container .container .row .guest-checkout-form-wrapper.guest-checkout-variation .guest-checkout-wrapper-container .guest-checkout-wrap .c-form-wrapper .form-element.active .checkbox-label {
  padding: 15px 22px;
}
.mypayment--page .page-container .container .row .guest-checkout-form-wrapper.recipient-details-form.guest-checkout-variation .email-control-wrapper-2 .form-element, .renewpasspayment--page .page-container .container .row .guest-checkout-form-wrapper.recipient-details-form.guest-checkout-variation .email-control-wrapper-2 .form-element {
  margin-left: 0;
}

.header-bottom {
  position: relative;
  color: #fff;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row-reverse;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .header-bottom {
    display: block;
    background-color: transparent;
    margin-left: auto;
    margin-top: -7px;
    height: 45px;
    min-height: auto;
    text-align: right;
  }
}
.header-bottom-lang-switcher {
  margin: 32px 32px 0 0;
  align-self: initial;
  order: -1;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .header-bottom-lang-switcher {
    display: inline-block;
    margin-top: 0;
    order: unset;
  }
}
.header-bottom-lang-switcher .dropdown-menu .dropdown-item {
  padding: 8px 18px;
}
.header-bottom-lang-switcher .dropdown-menu .dropdown-item:not(:first-child) {
  border-top: 2px solid #d3d0d0;
}
.header-bottom-lang-switcher .dropdown-menu .dropdown-item span {
  font-weight: bold;
}
.header-bottom-btn-book-tickets {
  background: transparent;
  border-color: #fff;
}
.header-bottom-btn-book-tickets:hover a {
  color: #302c2b;
}
.header-bottom-btn-book-tickets a {
  color: #fff;
}
.header-bottom-btn-login {
  background: transparent;
}
.header-bottom-btn-login:hover a {
  color: #302c2b;
}
.header-bottom-btn-login a {
  color: #fff;
}
.header-bottom-btn-logout {
  background: transparent;
  text-align: right;
  margin: 0 75px 60px 84px;
  border-top: 2px dotted #a5a0a0;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .header-bottom-btn-logout {
    margin: 0;
    border-top: 2px solid #d3d0d0;
  }
}
.header-bottom-btn-logout a {
  margin: 0;
  padding: 30px 0;
  background: none;
  border: none;
  color: #fff;
  width: 100%;
  border-radius: 0;
  border-bottom: 2px dotted #a5a0a0;
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.83;
  letter-spacing: 2px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  font-weight: 700;
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .header-bottom-btn-logout a {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .header-bottom-btn-logout a {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .header-bottom-btn-logout a {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.lang-ru .header-bottom-btn-logout a {
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 2px;
  line-height: 14px;
  font-family: "NotoSansBold", "Open Sans", Arial, sans-serif;
  font-weight: bold;
}
.lang-zh .header-bottom-btn-logout a {
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 2px;
  line-height: 14px;
  font-family: "PingFang SC", "STHeiti", "华文黑体", "Microsoft YaHei", "微软雅黑", "GothamBold", "Open Sans", Arial, sans-serif;
  font-weight: bold;
  font-weight: bold;
}
@media only screen and (min-width: 1024px) {
  .header-bottom-btn-logout a {
    font-family: "GothamBold", "Open Sans", Arial, sans-serif;
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.83;
    letter-spacing: 2px;
    /* Small devices (tablets, 768px and up) */
    /* Medium devices (desktops, 1024px and up) */
    /* Medium devices (desktops, 1366px and up) */
    text-align: left;
    padding: 12px;
    background-color: #f6f6f6;
    border-bottom: 0;
  }
}
@media only screen and (min-width: 1024px) and (min-width: 768px) {
  .header-bottom-btn-logout a {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) and (min-width: 1024px) {
  .header-bottom-btn-logout a {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) and (min-width: 1366px) {
  .header-bottom-btn-logout a {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ru .header-bottom-btn-logout a {
    font-size: 12px;
    font-size: 0.75rem;
    letter-spacing: 2px;
    line-height: 14px;
    font-family: "NotoSansBold", "Open Sans", Arial, sans-serif;
    font-weight: bold;
  }
  .lang-zh .header-bottom-btn-logout a {
    font-size: 12px;
    font-size: 0.75rem;
    letter-spacing: 2px;
    line-height: 14px;
    font-family: "PingFang SC", "STHeiti", "华文黑体", "Microsoft YaHei", "微软雅黑", "GothamBold", "Open Sans", Arial, sans-serif;
    font-weight: bold;
    font-weight: bold;
  }
}
.header-bottom-btn-logout a:hover, .header-bottom-btn-logout a:focus {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .header-bottom-btn-logout a:hover, .header-bottom-btn-logout a:focus {
    color: #999;
  }
}
.header-bottom-logged .header-bottom-btn-logout {
  min-height: 230px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .header-bottom-logged .header-bottom-btn-logout {
    min-height: auto;
  }
}
.header-bottom-profile {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .header-bottom-profile {
    display: inline;
    overflow: hidden;
  }
}
.header-bottom-profile .profile {
  position: relative;
  top: 20%;
}
.header-bottom-profile .header-bottom-profile-tooltip {
  display: none;
}
.header-bottom-profile:hover .header-bottom-profile-tooltip ul,
.header-bottom-profile:hover .header-bottom-profile-tooltip .header-bottom-btn-logout, .header-bottom-profile:focus-within .header-bottom-profile-tooltip ul,
.header-bottom-profile:focus-within .header-bottom-profile-tooltip .header-bottom-btn-logout {
  border-top: none !important;
}
.header-bottom-profile:hover .header-bottom-profile-tooltip ul a,
.header-bottom-profile:hover .header-bottom-profile-tooltip .header-bottom-btn-logout a, .header-bottom-profile:focus-within .header-bottom-profile-tooltip ul a,
.header-bottom-profile:focus-within .header-bottom-profile-tooltip .header-bottom-btn-logout a {
  box-sizing: border-box;
  padding: 12px 0px;
  border-bottom: 1px solid #D3D0D0;
}
.lang-ar .header-bottom-profile:hover .header-bottom-profile-tooltip ul a,
.lang-ar .header-bottom-profile:hover .header-bottom-profile-tooltip .header-bottom-btn-logout a, .lang-ar .header-bottom-profile:focus-within .header-bottom-profile-tooltip ul a,
.lang-ar .header-bottom-profile:focus-within .header-bottom-profile-tooltip .header-bottom-btn-logout a {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif !important;
}
.header-bottom-profile:hover .header-bottom-profile-tooltip ul a:focus,
.header-bottom-profile:hover .header-bottom-profile-tooltip .header-bottom-btn-logout a:focus, .header-bottom-profile:focus-within .header-bottom-profile-tooltip ul a:focus,
.header-bottom-profile:focus-within .header-bottom-profile-tooltip .header-bottom-btn-logout a:focus {
  color: #999;
}
.header-bottom-profile:hover .header-bottom-profile-tooltip li, .header-bottom-profile:focus-within .header-bottom-profile-tooltip li {
  border-bottom: none !important;
}
.header-bottom-profile:hover .header-bottom-profile-tooltip li:last-child, .header-bottom-profile:focus-within .header-bottom-profile-tooltip li:last-child {
  border-bottom: none !important;
}
.header-bottom-profile:hover .header-bottom-profile-tooltip .header-bottom-btn-logout a, .header-bottom-profile:focus-within .header-bottom-profile-tooltip .header-bottom-btn-logout a {
  border-bottom: none;
  font-family: "GothamMedium", "Open Sans", Arial, sans-serif !important;
  font-weight: 500;
  letter-spacing: 1px;
}
.header-bottom-profile.focus-within .header-bottom-profile-tooltip ul a,
.header-bottom-profile.focus-within .header-bottom-profile-tooltip .header-bottom-btn-logout a {
  box-sizing: border-box;
  padding: 12px 0px;
  border-bottom: 1px solid #D3D0D0;
}
.header-bottom-profile.focus-within .header-bottom-profile-tooltip ul a:focus,
.header-bottom-profile.focus-within .header-bottom-profile-tooltip .header-bottom-btn-logout a:focus {
  color: #999;
}
.header-bottom-profile.focus-within .header-bottom-profile-tooltip .header-bottom-btn-logout a {
  border-bottom: none;
  font-family: "GothamMedium", "Open Sans", Arial, sans-serif !important;
  font-weight: 500;
  letter-spacing: 1px;
}
.header-bottom-profile-tooltip {
  background-color: #fff;
  color: #302c2b;
  /* Medium devices (desktops, 1024px and up) */
}
.header-bottom-profile-tooltip a {
  color: #333;
  width: 100%;
  padding: 12px;
}
@media only screen and (min-width: 1024px) {
  .header-bottom-profile-tooltip {
    top: 36px;
    right: -12px;
    width: 240px;
    border-radius: 4px;
    border-color: #f6f6f6;
    text-align: left;
    padding: 15px 20px 0;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
    background: #f6f6f6;
  }
  .header-bottom-profile-tooltip:before {
    border-bottom: 12px solid #f6f6f6;
    right: 8%;
  }
  .header-bottom-profile-tooltip .header-bottom-profile-name {
    font-family: "GothamBold", "Open Sans", Arial, sans-serif;
    letter-spacing: 0;
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.22;
    /* Small devices (tablets, 768px and up) */
    /* Medium devices (desktops, 1024px and up) */
    /* Medium devices (desktops, 1366px and up) */
    font-weight: bold;
    padding: 8px 0px;
    background-color: #f6f6f6;
  }
}
@media only screen and (min-width: 1024px) and (min-width: 768px) {
  .header-bottom-profile-tooltip .header-bottom-profile-name {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1024px) and (min-width: 1024px) {
  .header-bottom-profile-tooltip .header-bottom-profile-name {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1024px) and (min-width: 1366px) {
  .header-bottom-profile-tooltip .header-bottom-profile-name {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .header-bottom-profile-tooltip ul li {
    font: 700 12px "Gotham";
    background: transparent;
    border-top: none;
    padding: 0;
  }
  .header-bottom-profile-tooltip ul li a {
    font-family: "GothamBold", "Open Sans", Arial, sans-serif;
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.83;
    letter-spacing: 2px;
    /* Small devices (tablets, 768px and up) */
    /* Medium devices (desktops, 1024px and up) */
    /* Medium devices (desktops, 1366px and up) */
    font-family: "GothamMedium", "Open Sans", Arial, sans-serif !important;
    padding: 15px 0 !important;
    color: #000;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}
@media only screen and (min-width: 1024px) and (min-width: 768px) {
  .header-bottom-profile-tooltip ul li a {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) and (min-width: 1024px) {
  .header-bottom-profile-tooltip ul li a {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) and (min-width: 1366px) {
  .header-bottom-profile-tooltip ul li a {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ru .header-bottom-profile-tooltip ul li a {
    font-size: 12px;
    font-size: 0.75rem;
    letter-spacing: 2px;
    line-height: 14px;
    font-family: "NotoSansBold", "Open Sans", Arial, sans-serif;
    font-weight: bold;
  }
  .lang-zh .header-bottom-profile-tooltip ul li a {
    font-size: 12px;
    font-size: 0.75rem;
    letter-spacing: 2px;
    line-height: 14px;
    font-family: "PingFang SC", "STHeiti", "华文黑体", "Microsoft YaHei", "微软雅黑", "GothamBold", "Open Sans", Arial, sans-serif;
    font-weight: bold;
    font-weight: bold;
  }
  .header-bottom-profile-tooltip ul li a:focus {
    color: #999;
  }
  .header-bottom-profile-tooltip ul li:hover a {
    color: #999;
  }
  .header-bottom-profile-tooltip ul li:last-child {
    border-bottom: 0;
  }
}
.header-bottom .header-bottom-profile {
  padding-bottom: 12px;
}
.header-bottom .btn.btn-secondary a {
  background: transparent;
  color: #fff;
}
.header-bottom .btn.btn-secondary a:hover {
  background: #fff;
  color: #333;
}
.header-bottom .search,
.header-bottom .profile {
  border: 0;
  position: relative;
  /* Medium devices (desktops, 1024px and up) */
}
.header-bottom .search.logged-in,
.header-bottom .profile.logged-in {
  margin-right: 16px;
}
.header-bottom .search.logged-in .icn-user,
.header-bottom .profile.logged-in .icn-user {
  display: none;
}
.header-bottom .search.logged-in .logged-in-icon,
.header-bottom .profile.logged-in .logged-in-icon {
  display: block;
  width: 20px;
  margin: auto;
  margin-right: 13px;
}
.header-bottom .search.logged-in span,
.header-bottom .profile.logged-in span {
  width: 100%;
  height: 20px;
  display: inline-block;
}
.header-bottom .search.logged-in span:after,
.header-bottom .profile.logged-in span:after {
  border: 2px solid #ffdf00;
  border-left: 0;
  border-bottom: 0;
  width: 6px;
  height: 6px;
  margin-left: 8px;
  left: 5px;
  top: 6px;
  transform: translate(15px) rotate(135deg);
}
.lang-ar .header-bottom .search.logged-in span:after,
.lang-ar .header-bottom .profile.logged-in span:after {
  left: -22px;
}
.header-bottom .search.logged-in:after,
.header-bottom .profile.logged-in:after {
  content: "";
  position: absolute;
  right: 1px;
  top: 8px;
  width: 5px;
  height: 5px;
  border: solid #fff;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}
@media only screen and (min-width: 1024px) {
  .header-bottom .search,
  .header-bottom .profile {
    margin: 8px 6px 4px 0px;
    top: 18%;
    width: 20px;
    height: 20px;
  }
}
.header-bottom .btn-primary {
  margin-top: 12px;
  margin-left: 16px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .header-bottom .btn-primary {
    margin-top: -8px;
    margin-left: 0;
  }
}
.header-bottom .btn-primary a {
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.83;
  letter-spacing: 2px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  color: #333;
  font-family: "GothamBlack", "Open Sans", Arial, sans-serif;
  letter-spacing: 1px !important;
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .header-bottom .btn-primary a {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .header-bottom .btn-primary a {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .header-bottom .btn-primary a {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.lang-ru .header-bottom .btn-primary a {
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 2px;
  line-height: 14px;
  font-family: "NotoSansBold", "Open Sans", Arial, sans-serif;
  font-weight: bold;
}
.lang-zh .header-bottom .btn-primary a {
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 2px;
  line-height: 14px;
  font-family: "PingFang SC", "STHeiti", "华文黑体", "Microsoft YaHei", "微软雅黑", "GothamBold", "Open Sans", Arial, sans-serif;
  font-weight: bold;
  font-weight: bold;
}
.header-bottom .btn-primary a:before {
  background: #fff;
  top: 8px;
  left: 8px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .header-bottom .btn-primary a:before {
    background: #000;
    border: 2px solid #fff;
  }
}
@media only screen and (min-width: 1024px) {
  .header-bottom .btn-primary a:hover {
    background-color: #fff;
  }
  .header-bottom .btn-primary a:hover:before {
    top: 5px;
    left: 5px;
    border: solid 2px #ffdf00;
  }
}
.header-bottom a.hide-visibility {
  display: none;
}
.header-bottom a:focus {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .header-bottom a:focus {
    box-sizing: content-box;
  }
}

.c-header .c-header-top-icon.cart {
  border: 0;
  right: 100px;
  position: absolute;
  margin: 0;
  align-self: center;
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
.lang-ar .c-header .c-header-top-icon.cart {
  right: 0;
}
.custom-mobile-header .c-header .c-header-top-icon.cart {
  border: 0px;
}
@media only screen and (min-width: 1024px) {
  .c-header .c-header-top-icon.cart {
    top: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .c-header .c-header-top-icon.cart {
    right: 0;
    position: relative;
    margin: 8px 12px 4px 6px;
    top: 18%;
    width: 20px;
    height: 20px;
  }
}
.c-header .c-header-top-icon.cart .total-quantity {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.83;
  letter-spacing: 0.1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 18px;
  border-radius: 50%;
  min-width: 23px;
  min-height: 23px;
  position: absolute;
  text-align: center;
  background: #ffdf00;
  color: #000;
  left: 18px;
  top: -7px;
  padding: 2px 2px;
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-header .c-header-top-icon.cart .total-quantity {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-header .c-header-top-icon.cart .total-quantity {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .c-header .c-header-top-icon.cart .total-quantity {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-header .c-header-top-icon.cart .total-quantity {
    left: 18px;
    top: -6px;
  }
}

.header-body {
  color: #fff;
  border-top: 0;
  border-bottom: 0;
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
.header-body-b2c {
  border: none;
}
@media only screen and (min-width: 1024px) {
  .header-body {
    display: block;
    background-color: transparent;
    position: absolute;
    top: 64%;
    width: 100%;
    left: auto;
    right: 0;
    z-index: 1;
  }
}
@media only screen and (min-width: 1366px) {
  .header-body {
    top: 60%;
  }
}
.header-body a {
  color: #fff;
}
.header-body-content-logged-search-bar {
  background-color: transparent;
}
.header-body-rte {
  color: #fff;
}
.header-body-profile ul {
  text-align: right;
  margin: 60px 75px 0 84px;
}
.header-body-profile ul li {
  border-bottom: 1px dotted #a5a0a0;
  line-height: 0;
  padding: 30px 0px;
}
.header-body-profile ul li:last-child {
  border: 0;
}
.header-body-profile ul li a {
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.83;
  letter-spacing: 2px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .header-body-profile ul li a {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .header-body-profile ul li a {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .header-body-profile ul li a {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.lang-ru .header-body-profile ul li a {
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 2px;
  line-height: 14px;
  font-family: "NotoSansBold", "Open Sans", Arial, sans-serif;
  font-weight: bold;
}
.lang-zh .header-body-profile ul li a {
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 2px;
  line-height: 14px;
  font-family: "PingFang SC", "STHeiti", "华文黑体", "Microsoft YaHei", "微软雅黑", "GothamBold", "Open Sans", Arial, sans-serif;
  font-weight: bold;
  font-weight: bold;
}
.header-body-profile-name {
  border: 0;
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  letter-spacing: 0;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.22;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  font-family: "GothamBlack", "Open Sans", Arial, sans-serif;
  padding-top: 76px;
}
@media (min-width: 768px) {
  .header-body-profile-name {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .header-body-profile-name {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1366px) {
  .header-body-profile-name {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
.header-body-btn-register {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .header-body-btn-register {
    position: absolute;
    bottom: 38px;
    width: auto;
    right: 348px;
  }
}
.header-body-btn-register a {
  background: transparent;
  color: white;
  border-color: white;
}
.header-body-nav-links {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .header-body-nav-links {
    float: right;
    width: auto;
  }
}
.header-body-content-logged {
  display: block;
}
.header-body-content-logged nav {
  margin: 26px 44px 0;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .header-body-content-logged nav {
    margin: 60px 84px;
  }
}

.nav--wrapper {
  text-align: right;
  /* Medium devices (desktops, 1024px and up) */
}
.nav--wrapper-show .header-nav-link {
  position: relative;
}
.nav--wrapper-show .header-nav-link:after {
  content: "";
  position: absolute;
  right: -24px;
  top: 45%;
  width: 12px;
  height: 3px;
  background: #fff;
}
@media only screen and (min-width: 1024px) {
  .nav--wrapper {
    float: right;
    height: auto;
    margin-top: 2px;
  }
}
.nav--wrapper-content {
  padding: 15px 0 22px;
  line-height: 0;
  /* Medium devices (desktops, 1024px and up) */
  color: #fff;
}
.nav--wrapper-content-item {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .nav--wrapper-content {
    padding: 0;
  }
}
.nav--wrapper-content span .header-nav-link, .nav--wrapper-content .header-nav-link {
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.83;
  letter-spacing: 2px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  text-align: right;
  color: #fff;
}
@media (min-width: 768px) {
  .nav--wrapper-content span .header-nav-link, .nav--wrapper-content .header-nav-link {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nav--wrapper-content span .header-nav-link, .nav--wrapper-content .header-nav-link {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .nav--wrapper-content span .header-nav-link, .nav--wrapper-content .header-nav-link {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.lang-ru .nav--wrapper-content span .header-nav-link, .lang-ru .nav--wrapper-content .header-nav-link {
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 2px;
  line-height: 14px;
  font-family: "NotoSansBold", "Open Sans", Arial, sans-serif;
  font-weight: bold;
}
.lang-zh .nav--wrapper-content span .header-nav-link, .lang-zh .nav--wrapper-content .header-nav-link {
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 2px;
  line-height: 14px;
  font-family: "PingFang SC", "STHeiti", "华文黑体", "Microsoft YaHei", "微软雅黑", "GothamBold", "Open Sans", Arial, sans-serif;
  font-weight: bold;
  font-weight: bold;
}
.nav--wrapper-content span .header-nav-link:hover, .nav--wrapper-content .header-nav-link:hover {
  color: #ffdf00;
}
.nav--wrapper-content span .header-nav-link.nav-selected, .nav--wrapper-content .header-nav-link.nav-selected {
  color: #ffdf00;
}
.nav--wrapper-content + .nav--wrapper-content {
  border-top: 1px dotted #a5a0a0;
}
.nav--wrapper-content:last-child {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .nav--wrapper-content:last-child {
    margin-right: 0;
  }
}
.nav--wrapper-content.has-submenu.open .meganav-hoverable > div {
  width: 0;
  height: 0;
  margin-left: 40%;
  margin-top: 5px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #f6f6f6;
}
.nav--wrapper-content.has-submenu.open .meganav-hoverable > div .meganav {
  display: block;
}

.language-switcher {
  font-size: 12px;
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
}
.language-switcher .dropdown-component .selected-option {
  position: relative;
  color: #fff;
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.83;
  letter-spacing: 2px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
.language-switcher .dropdown-component .selected-option:before {
  display: none;
}
.language-switcher .dropdown-component .selected-option:after {
  border: solid;
  border-width: 0 2px 2px 0;
  height: 6px;
  width: 6px;
  top: 10px;
  right: 11px;
  transform: rotate(225deg);
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .language-switcher .dropdown-component .selected-option:after {
    width: 5px;
    height: 5px;
  }
}
.lang-ar .language-switcher .dropdown-component .selected-option:after {
  top: 8px;
}
.lang-zh .language-switcher .dropdown-component .selected-option:after {
  top: 6px;
}
.language-switcher .dropdown-component .selected-option.collapse:after {
  top: 7px;
  transform: rotate(45deg);
}
.lang-zh .language-switcher .dropdown-component .selected-option.collapse:after {
  top: 3px;
}
.lang-ar .language-switcher .dropdown-component .selected-option.collapse:after {
  top: 6px;
}
@media (min-width: 768px) {
  .language-switcher .dropdown-component .selected-option {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .language-switcher .dropdown-component .selected-option {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .language-switcher .dropdown-component .selected-option {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.lang-ru .language-switcher .dropdown-component .selected-option {
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 2px;
  line-height: 14px;
  font-family: "NotoSansBold", "Open Sans", Arial, sans-serif;
  font-weight: bold;
}
.lang-zh .language-switcher .dropdown-component .selected-option {
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 2px;
  line-height: 14px;
  font-family: "PingFang SC", "STHeiti", "华文黑体", "Microsoft YaHei", "微软雅黑", "GothamBold", "Open Sans", Arial, sans-serif;
  font-weight: bold;
  font-weight: bold;
}
.language-switcher .selected-option {
  padding-right: 20px;
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.83;
  letter-spacing: 2px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .language-switcher .selected-option {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .language-switcher .selected-option {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .language-switcher .selected-option {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.lang-ru .language-switcher .selected-option {
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 2px;
  line-height: 14px;
  font-family: "NotoSansBold", "Open Sans", Arial, sans-serif;
  font-weight: bold;
}
.lang-zh .language-switcher .selected-option {
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 2px;
  line-height: 14px;
  font-family: "PingFang SC", "STHeiti", "华文黑体", "Microsoft YaHei", "微软雅黑", "GothamBold", "Open Sans", Arial, sans-serif;
  font-weight: bold;
  font-weight: bold;
}
.language-switcher .selected-option:after {
  right: 0;
  top: 9px;
}
.language-switcher .selected-option.language-switcher--lng-label {
  padding-right: 0;
}
.language-switcher .selected-option.language-switcher--lng-label:after {
  display: none;
}
.language-switcher .dropdown-menu {
  background-color: #635c5c;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  right: -8px;
  top: auto;
  /* < 768px */
  /* Medium devices (desktops, 1024px and up) */
  border-radius: 4px;
}
@media (min-width: 320px) and (max-width: 767px) {
  .language-switcher .dropdown-menu {
    z-index: 50;
  }
}
@media only screen and (min-width: 1024px) {
  .language-switcher .dropdown-menu {
    top: 42px;
  }
}
.language-switcher .dropdown-menu li + li {
  border-top: solid 1px #d3d0d0;
}
.language-switcher .dropdown-menu:before {
  border-bottom-color: #f6f6f6;
}
.language-switcher .dropdown-item {
  padding: 16px 18px;
  letter-spacing: 1.8px;
  color: #000;
  background: #f6f6f6;
}
.language-switcher .dropdown-item > div {
  padding: 0;
  border-top: 0;
}
.language-switcher .dropdown-item:hover a {
  color: #999;
}
.language-switcher .dropdown-item a {
  padding-top: 4px;
  text-align: left;
}
.lang-ar .language-switcher .dropdown-item a {
  text-align: right;
}

/* Medium devices (desktops, 1024px and up) */
@media only screen and (min-width: 1024px) {
  .header-body .header-body-nav-links .nav--wrapper-content {
    position: relative;
    margin-right: 0;
  }
  .header-body .header-body-nav-links .nav--wrapper-content.has-submenu > span > a:before {
    content: "";
    top: 8px;
    height: 6px;
    width: 11px;
    border: 0;
    transform: none;
    position: absolute;
    right: 20px;
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMSIgaGVpZ2h0PSI2IiB2aWV3Qm94PSIwIDAgMTEgNiI+CiAgICA8cGF0aCBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0uMjEuMjAzYS43MzguNzM4IDAgMCAxIDEuMDE5IDBMNS41IDQuMzIzIDkuNzcyLjIwNGEuNzM4LjczOCAwIDAgMSAxLjAxNyAwYy4yODEuMjcxLjI4MS43MTEgMCAuOTgyTDYuMDEgNS43OTdhLjczOC43MzggMCAwIDEtMS4wMTggMEwuMjExIDEuMTg1YS42OC42OCAwIDAgMSAwLS45ODJ6Ii8+Cjwvc3ZnPgo=") no-repeat;
  }
  .header-body .header-body-nav-links .nav--wrapper-content.has-submenu > span > a.open:before {
    transform: rotate(180deg);
  }
  .header-body .header-body-nav-links .nav--wrapper-content > span > a {
    position: relative;
    color: #fff;
    padding-right: 40px;
    padding-left: 20px;
  }
  .header-body .header-body-nav-links .nav--wrapper-content > span > a:last-child {
    padding-right: 15px;
  }
  .lang-ar .header-body .header-body-nav-links .nav--wrapper-content > span > a {
    padding-right: 0;
  }
  .header-body .header-body-nav-links .nav--wrapper-content + .nav--wrapper-content {
    border-top: 0;
    position: relative;
  }
  .header-body .header-body-nav-links .nav--wrapper-content + .nav--wrapper-content:after {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    left: 0;
    border-radius: 4px;
    height: 12px;
    right: initial;
    top: 6px;
    border-left: 1px dotted #a5a0a0;
  }
  .header-body .header-body-nav-links .nav--wrapper-content + .nav--wrapper-content:hover {
    border-bottom: 0;
  }
  .header-body .header-body-nav-links .nav--wrapper-content + .nav--wrapper-content:hover span:hover > div, .header-body .header-body-nav-links .nav--wrapper-content + .nav--wrapper-content:hover > div {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #fff;
    margin-left: 40%;
    margin-top: 5px;
  }
  .header-body .header-body-nav-links .nav--wrapper-content + .nav--wrapper-content:hover > a {
    border: none;
    color: #fff;
  }
  .header-body .header-body-nav-links .nav--wrapper-content + .nav--wrapper-content:hover > a:hover {
    color: #fff;
  }
}
.lang-ar .header-bottom {
  padding: 0;
  text-align: left;
}
.lang-ar .header-bottom .btn-primary {
  margin-left: auto;
  margin-right: 16px;
}
.lang-ar .header-bottom .cart {
  border: 0;
  right: auto;
  left: 100px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .header-bottom .cart {
    right: auto;
    left: 0;
    margin: 8px 6px 4px 12px;
  }
}
.lang-ar .header-bottom .cart .total-quantity {
  font-weight: 700;
}
.lang-ar .header-bottom .search,
.lang-ar .header-bottom .profile {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .header-bottom .search,
  .lang-ar .header-bottom .profile {
    margin: 10px 6px 4px 6px;
  }
}
.lang-ar .header-bottom .search.logged-in,
.lang-ar .header-bottom .profile.logged-in {
  margin-left: 12px;
}
.lang-ar .header-bottom .search.logged-in:after,
.lang-ar .header-bottom .profile.logged-in:after {
  right: auto;
  left: 1px;
}
.lang-ar .header-bottom .search.logged-in .logged-in-icon,
.lang-ar .header-bottom .profile.logged-in .logged-in-icon {
  margin-left: 13px;
  margin-right: auto;
}
.lang-ar .header-bottom-lang-switcher {
  margin: 32px 0 0 32px;
}
.lang-ar .header-bottom-profile .header-bottom-profile-tooltip {
  left: -15px;
  right: auto;
}
.lang-ar .header-bottom-btn-logout a {
  text-align: right;
}

.lang-ar .header-body {
  left: 0;
  right: auto;
}
.lang-ar .header-body-nav-links {
  float: left;
}
.lang-ar .header-body-profile ul {
  text-align: right;
}
.lang-ar .header-body-content-logged nav {
  margin: 60px 84px 60px 75px;
}
.lang-ar .header-body-b2c:before {
  right: auto;
  left: 24%;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .lang-ar .header-body-b2c:before {
    left: 12%;
  }
}
.lang-ar .header-body-logged {
  padding-left: 0;
}
.lang-ar .header-body .autocomplete-wrapper .btn-submit {
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .lang-ar .header-body .autocomplete-wrapper .btn-submit {
    right: auto;
    left: 24px;
  }
}
.lang-ar .header-body .autocomplete-wrapper .btn-reset {
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .lang-ar .header-body .autocomplete-wrapper .btn-reset {
    right: auto;
    left: 0;
  }
}
.lang-ar .header-body .header-body-nav-links .nav--wrapper-content {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .header-body .header-body-nav-links .nav--wrapper-content {
    padding-right: 30px;
    padding-left: 0;
    margin-right: 30px;
  }
  .lang-ar .header-body .header-body-nav-links .nav--wrapper-content > span > a {
    padding-left: 20px;
    padding-right: 0;
  }
}
.lang-ar .c-header .dropdown-item[data-value=EN] span {
  letter-spacing: 2px !important;
  font-family: "GothamBold", "Open Sans", Arial, sans-serif !important;
}
.lang-ar .c-header .nav-item-search {
  right: -22px;
}
.lang-ar .c-header .nav-item-search .overlay--close-button {
  left: 10px;
  right: auto;
  border-left: 0;
  border-right: 1px solid #fff;
  padding-left: 0;
  padding-right: 12px;
}
.lang-ar .c-header .nav-item-search .autocomplete-wrapper .input-container {
  padding-right: 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-header .nav-item-search .autocomplete-wrapper .input-container .form-input {
    padding-right: 50px;
    margin-right: 0;
    margin-left: 12px;
  }
}
.lang-ar .c-header-top .profile {
  left: 78px;
  right: auto;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .lang-ar .c-header-top .profile {
    left: 84px;
  }
}
.lang-ar .c-header-top .whatsapp {
  left: 120px;
  right: auto;
}
.lang-ar .c-header .c-header-top-icon.hamburger {
  left: 30px;
  right: initial;
}
.lang-ar .c-header .c-header-top-icon.close {
  left: 24px;
  right: auto;
}
.lang-ar .c-header .c-header-top {
  /* < 768px */
}
.lang-ar .c-header .c-header-top-logo {
  /* Medium devices (desktops, 1366px and up) */
}
.lang-ar .c-header .c-header-top-logo img {
  width: auto;
}
@media only screen and (min-width: 1366px) {
  .lang-ar .c-header .c-header-top-logo img {
    width: 160px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .lang-ar .c-header .c-header-top {
    padding: 8px 20px 7px 28px;
  }
}
.lang-ar .c-header.sticky-header {
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1366px) {
  .lang-ar .c-header.sticky-header .c-header-wrapper {
    padding-right: 33px;
  }
  .lang-ar .c-header.sticky-header .header-body {
    margin-left: 14%;
    margin-right: 0;
  }
  .lang-ar .c-header.sticky-header .header-bottom {
    right: auto;
    left: 5px;
  }
  .lang-ar .c-header.sticky-header .header-bottom .btn-primary a {
    right: auto;
    left: 30px;
    /* Medium devices (desktops, 1024px and up) */
  }
}
@media only screen and (min-width: 1366px) and (min-width: 1024px) {
  .lang-ar .c-header.sticky-header .header-bottom .btn-primary a {
    bottom: 1px;
    right: 3px;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .c-header.sticky-header .header-bottom .btn-primary a:hover:before {
    top: 5px;
    left: 7px;
    right: 7px;
  }
  .lang-ar .c-header.sticky-header .c-header-wrapper .c-header-top-logo {
    float: right;
  }
}
.lang-ar .meganav--text-wrapper {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .meganav--text-wrapper {
    padding-right: 0;
    padding-left: 64px;
  }
}
.lang-ar .meganav--text-wrapper li {
  text-align: left;
  font-weight: bold;
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  letter-spacing: 0;
  /* < 768px */
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
.lang-ar .meganav--text-wrapper li.meganav--text-wrapper-list-item:hover {
  font-weight: inherit;
}
@media (min-width: 320px) and (max-width: 767px) {
  .lang-ar .meganav--text-wrapper li {
    font-size: 16px;
    font-size: 1rem;
    line-height: 28px;
    line-height: 1.75rem;
  }
}
@media (min-width: 768px) {
  .lang-ar .meganav--text-wrapper li {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 24px;
    line-height: 1.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .meganav--text-wrapper li {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 24px;
    line-height: 1.5rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .meganav--text-wrapper li {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 24px;
    line-height: 1.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .meganav--text-wrapper li {
    text-align: right;
  }
}
.lang-ar .meganav--text-wrapper-bodycopy {
  text-align: right;
}
.lang-ar .meganav--image-wrapper-list-content p,
.lang-ar .meganav--image-wrapper-list div p {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.lang-ar .header-bottom {
  padding: 0 20px 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .lang-ar .header-bottom {
    padding: 12px 22px 18px;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .header-bottom {
    position: absolute;
    margin-top: -6px;
    left: 0;
    right: initial;
    padding: 0;
  }
}
.lang-ar .header-bottom-profile-tooltip {
  text-align: right;
  right: -180px;
}
.lang-ar .header-bottom-profile-tooltip:before {
  right: 79%;
}
.lang-ar .header-bottom .btn-primary {
  margin-left: 0;
  padding-right: 0;
  margin-right: 0;
}
.lang-ar .header-bottom .btn-primary a {
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.83;
  letter-spacing: 2px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
@media (min-width: 768px) {
  .lang-ar .header-bottom .btn-primary a {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .header-bottom .btn-primary a {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .header-bottom .btn-primary a {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.lang-ru .lang-ar .header-bottom .btn-primary a {
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 2px;
  line-height: 14px;
  font-family: "NotoSansBold", "Open Sans", Arial, sans-serif;
  font-weight: bold;
}
.lang-zh .lang-ar .header-bottom .btn-primary a {
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 2px;
  line-height: 14px;
  font-family: "PingFang SC", "STHeiti", "华文黑体", "Microsoft YaHei", "微软雅黑", "GothamBold", "Open Sans", Arial, sans-serif;
  font-weight: bold;
  font-weight: bold;
}
.lang-ar .header-bottom .search {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .header-bottom .search {
    margin-right: 0 4px;
  }
}
.lang-ar .header-bottom-profile .profile.logged-in {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .header-bottom-profile .profile.logged-in {
    background-position: right;
  }
}
.lang-ar .header-bottom-lang-switcher {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .header-bottom-lang-switcher {
    margin-left: 32px;
  }
}
.lang-ar .header-bottom .selected-option {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: bold;
  letter-spacing: 0;
  /* < 768px */
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  /* Medium devices (desktops, 1024px and up) */
  padding-left: 20px;
}
@media (min-width: 320px) and (max-width: 767px) {
  .lang-ar .header-bottom .selected-option {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 22px;
    line-height: 1.375rem;
  }
}
@media (min-width: 768px) {
  .lang-ar .header-bottom .selected-option {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 22px;
    line-height: 1.375rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .header-bottom .selected-option {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 22px;
    line-height: 1.375rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .header-bottom .selected-option {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 20px;
    line-height: 1.25rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .header-bottom .selected-option {
    border-left: 0;
  }
}
.lang-ar .header-bottom .selected-option:after {
  right: calc(100% - 13px);
}
.lang-ar .nav--wrapper {
  margin-right: 0;
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .nav--wrapper {
    margin-top: 4px;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .nav--wrapper {
    margin-top: 8px;
  }
}
.lang-ar .nav--wrapper-show .link:after,
.lang-ar .nav--wrapper-show .header-nav-link:after {
  left: -24px;
  right: initial;
}
.lang-ar .nav--wrapper-show > div {
  border-top-right-radius: 90%;
}
.lang-ar .nav--wrapper-show.mega-nav-variant {
  /* Medium devices (desktops, 1024px and up) */
}
.lang-ar .nav--wrapper-show.mega-nav-variant .meganav li a {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  letter-spacing: 0;
  /* < 768px */
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 320px) and (max-width: 767px) {
  .lang-ar .nav--wrapper-show.mega-nav-variant .meganav li a {
    font-size: 16px;
    font-size: 1rem;
    line-height: 28px;
    line-height: 1.75rem;
  }
}
@media (min-width: 768px) {
  .lang-ar .nav--wrapper-show.mega-nav-variant .meganav li a {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 24px;
    line-height: 1.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .nav--wrapper-show.mega-nav-variant .meganav li a {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 24px;
    line-height: 1.5rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .nav--wrapper-show.mega-nav-variant .meganav li a {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 24px;
    line-height: 1.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .nav--wrapper-show.mega-nav-variant .meganav--text-wrapper {
    padding-left: 0;
  }
  .lang-ar .nav--wrapper-show.mega-nav-variant .meganav--text-wrapper > .meganav--wrapper-column span {
    text-align: right;
  }
  .lang-ar .nav--wrapper-show.mega-nav-variant .meganav--text-wrapper > .meganav--wrapper-column + .meganav--wrapper-column {
    padding-right: 30px;
    padding-left: 30px;
  }
  .lang-ar .nav--wrapper-show.mega-nav-variant .meganav--text-wrapper li {
    text-align: right;
  }
  .lang-ar .nav--wrapper-show.mega-nav-variant .meganav-show {
    left: -80px;
    right: auto;
  }
}
.lang-ar .nav--wrapper-content {
  text-align: left;
  padding-left: 0;
  /* Medium devices (desktops, 1024px and up) */
}
.lang-ar .nav--wrapper-content .header-nav-link {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: bold;
  letter-spacing: 0;
  /* < 768px */
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 320px) and (max-width: 767px) {
  .lang-ar .nav--wrapper-content .header-nav-link {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 22px;
    line-height: 1.375rem;
  }
}
@media (min-width: 768px) {
  .lang-ar .nav--wrapper-content .header-nav-link {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 22px;
    line-height: 1.375rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .nav--wrapper-content .header-nav-link {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 22px;
    line-height: 1.375rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .nav--wrapper-content .header-nav-link {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 20px;
    line-height: 1.25rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .nav--wrapper-content {
    margin-right: 24px;
  }
  .lang-ar .nav--wrapper-content.nav--wrapper-show > div {
    margin-left: 0;
    margin-right: 40%;
  }
  .lang-ar .nav--wrapper-content:first-child {
    border-right: 0;
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
  }
  .lang-ar .nav--wrapper-content:last-child {
    margin-right: 24px;
  }
  .lang-ar .nav--wrapper-content + .nav--wrapper-content {
    border-top: 0;
    margin-right: 30px;
    padding-right: 30px;
  }
  .lang-ar .nav--wrapper-content + .nav--wrapper-content > a:after {
    content: "";
    right: -20px;
    left: initial;
    border-left: 0;
    border-right: 1px dotted #a5a0a0;
  }
}
.lang-ar .nav--wrapper-content.multi-columns.mega-nav-variant {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .nav--wrapper-content.multi-columns.mega-nav-variant > div {
    margin-right: 30%;
  }
  .lang-ar .nav--wrapper-content.multi-columns.mega-nav-variant > div .meganav-show {
    right: auto;
    left: 20px;
  }
}
.lang-ar .nav--wrapper-content:last-child.mega-nav-variant .meganav-show {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .nav--wrapper-content:last-child.mega-nav-variant .meganav-show {
    left: -42px;
    right: auto;
  }
}
.lang-ar .language-switcher {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: bold;
}
.lang-ar .language-switcher .dropdown-menu {
  right: auto;
  left: 0;
  box-shadow: none;
}
.lang-ar .language-switcher .dropdown-menu:before {
  left: 8%;
  right: auto;
}
.lang-ar .language-switcher .dropdown-item span {
  padding-right: 0;
  text-align: right;
}

.lang-ar .meganav {
  /* Medium devices (desktops, 1024px and up) */
}
.lang-ar .meganav li a {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  letter-spacing: 0;
  /* < 768px */
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  font-weight: inherit;
}
@media (min-width: 320px) and (max-width: 767px) {
  .lang-ar .meganav li a {
    font-size: 16px;
    font-size: 1rem;
    line-height: 28px;
    line-height: 1.75rem;
  }
}
@media (min-width: 768px) {
  .lang-ar .meganav li a {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 24px;
    line-height: 1.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .meganav li a {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 24px;
    line-height: 1.5rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .meganav li a {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 24px;
    line-height: 1.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .meganav {
    left: -80px;
    right: auto;
  }
  .lang-ar .meganav--text-wrapper {
    padding-left: 0;
  }
  .lang-ar .meganav--wrapper-column {
    text-align: right;
    padding-left: 30px;
  }
  .lang-ar .meganav--wrapper-column:last-child {
    padding-left: 0;
  }
}

.lang-ar {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .nav--wrapper-content {
    margin-right: 24px;
    padding-right: 20px;
  }
  .lang-ar .nav--wrapper-content:last-child {
    padding-left: 0;
  }
  .lang-ar .nav--wrapper-content:last-child .meganav {
    left: 0;
    right: auto;
  }
  .lang-ar .nav--wrapper-content:after {
    right: 0;
  }
  .lang-ar .nav--wrapper-content:first-child .meganav {
    left: 20px;
  }
}
.lang-ar .language-switcher .selected-option {
  border: 0;
  padding-right: auto;
  padding-left: 25px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .language-switcher .selected-option {
    right: 7px;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
  }
}
.lang-ar .language-switcher .selected-option:after {
  right: calc(100% - 21px);
}

.c-header-revamp-component .revamp-header-container .c-header-top-icon span {
  display: flex;
  gap: 8px;
}
.c-header-revamp-component .revamp-header-container .tooltipOpen {
  color: #ffd90b !important;
}
.c-header-revamp-component .revamp-header-container .tooltipOpen a,
.c-header-revamp-component .revamp-header-container .tooltipOpen p,
.c-header-revamp-component .revamp-header-container .tooltipOpen span {
  color: #ffd90b !important;
}
.c-header-revamp-component .revamp-header-container .tooltipOpen a svg,
.c-header-revamp-component .revamp-header-container .tooltipOpen p svg,
.c-header-revamp-component .revamp-header-container .tooltipOpen span svg {
  stroke: #ffd90b !important;
}
.c-header-revamp-component .revamp-header-container .tooltipOpen a svg path,
.c-header-revamp-component .revamp-header-container .tooltipOpen p svg path,
.c-header-revamp-component .revamp-header-container .tooltipOpen span svg path {
  stroke: #ffd90b !important;
}
.c-header-revamp-component .revamp-header-container .tooltipOpen svg {
  stroke: #ffd90b !important;
}
.c-header-revamp-component .revamp-header-container .tooltipOpen svg path {
  stroke: #ffd90b !important;
}
.c-header-revamp-component .revamp-header-container .tooltipOpen svg.dropdown {
  transform: rotate(180deg);
}
.c-header-revamp-component .revamp-header-container .header-revamp-tooltip {
  position: relative;
}
.c-header-revamp-component .revamp-header-container .header-revamp-tooltip .revamp-header-nav-link-dropdown svg {
  width: 21px;
  height: 20px;
}
.c-header-revamp-component .revamp-header-container .header-revamp-tooltip .revamp-header-nav-link-dropdown svg path {
  stroke: #fff;
}
.c-header-revamp-component .revamp-header-container .header-revamp-tooltip .revamp-header-nav-link-dropdown.tooltipOpen svg {
  stroke: #ffd90b !important;
  transform: rotate(180deg);
}
.c-header-revamp-component .revamp-header-container .header-revamp-tooltip .revamp-header-nav-link-dropdown.tooltipOpen svg path {
  stroke: #ffd90b !important;
}
.c-header-revamp-component .revamp-header-container .header-revamp-dropdown {
  border-top: 4px solid #ffd90b;
  background: #fff;
  position: absolute;
  z-index: 10;
  top: 40px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  /* < 768px */
}
.c-header-revamp-component .revamp-header-container .header-revamp-dropdown .header-revamp-list-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.c-header-revamp-component .revamp-header-container .header-revamp-dropdown .helper-upcoming {
  display: flex;
  padding: 0px 4px 1px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background-color: #FFDF00;
  color: #000;
  font-size: 10px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component .revamp-header-container .header-revamp-dropdown.profile-dropdown-mini-header {
    width: 176px;
    padding: 8px 0px;
    top: 46px !important;
  }
  .c-header-revamp-component .revamp-header-container .header-revamp-dropdown.profile-dropdown-mini-header .header-revamp-list {
    width: 100%;
  }
  .c-header-revamp-component .revamp-header-container .header-revamp-dropdown.language-mini-header-mobile {
    width: 181px;
    padding: 8px 0px;
    top: 41px !important;
  }
  .c-header-revamp-component .revamp-header-container .header-revamp-dropdown.language-mini-header-mobile .header-revamp-list {
    width: 100%;
  }
}
.c-header-revamp-component .revamp-header-container .header-revamp-dropdown.language-mini-header-mobile {
  top: 40px !important;
}
.c-header-revamp-component .revamp-header-container .header-revamp-dropdown.profile-dropdown-mini-header {
  top: 40px !important;
}
.c-header-revamp-component .revamp-header-container .header-revamp-list {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  flex-direction: column;
  align-items: start;
  border-bottom: 1px solid #D3D3D3;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0.1px;
}
.c-header-revamp-component .revamp-header-container .header-revamp-list:last-child {
  border-bottom: none;
}
.c-header-revamp-component .revamp-header-container .header-revamp-list .logout-revamp-list {
  width: 21px;
  height: 20px;
}
.c-header-revamp-component .revamp-header-container .header-revamp-list.en {
  font-weight: 400;
  font-family: "Open Sans", Arial, sans-serif;
}
.c-header-revamp-component .revamp-header-container .header-revamp-list.logout-list-item {
  text-transform: none;
}
.c-header-revamp-component .revamp-header-container .header-revamp-list.logout-list-item a {
  text-decoration: none;
}
.lang-ar .c-header-revamp-component .revamp-header-container .header-revamp-list.logout-list-item {
  justify-content: revert;
}
.lang-ar .c-header-revamp-component .revamp-header-container .header-revamp-list.logout-list-item .logout-revamp-list {
  transform: rotate(180deg);
  gap: 8px;
}
.lang-ar .c-header-revamp-component .revamp-header-container .header-revamp-list.logout-list-item .logout-revamp-list .logout-icon {
  width: 21px;
  height: 20px;
}
.c-header-revamp-component .revamp-header-container .header-revamp-list.logout-list-item a:hover {
  color: #333333;
}
.c-header-revamp-component .revamp-header-container .header-revamp-list.selected {
  font-weight: 700;
}
.c-header-revamp-component .revamp-header-container .c-header-top-icon {
  width: unset;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top {
  display: flex;
  padding: 8px 35px 8px 265px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component .revamp-header-container .revamp-header-top {
    background: #000;
  }
  .c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header {
    background: #333333;
  }
}
.lang-ar .c-header-revamp-component .revamp-header-container .revamp-header-top {
  padding: 8px 200px 8px 35px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header {
  /* < 768px */
  /* Medium devices (desktops, 1024px and up) */
  background-color: #000;
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header {
    padding: 0px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header {
    padding: 20px 75px;
  }
}
.upgrade--page .c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .upgrade--page .c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header {
    padding: 8px 75px;
  }
}
.c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header .redirect-btn {
  color: #fff;
  font-feature-settings: "liga" off, "clig" off;
  font-weight: 400;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0px;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header .redirect-btn {
    padding: 0px;
  }
}
.c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header .redirect-btn svg {
  width: 21px;
  height: 20px;
}
.lang-ar .c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header .redirect-btn svg {
  transform: rotate(180deg);
}
.c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header .dark-logo picture img {
  padding: 0;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .language-header-name {
  font-family: "GothamBlack", "Open Sans", Arial, sans-serif;
  color: #fff;
  font-feature-settings: "liga" off, "clig" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 1px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .language-header-name svg path {
  stroke: #fff;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .language-header-name .logged-in-user-header {
  gap: 4px;
  color: #ffd90b;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component .revamp-header-container .revamp-header-top .language-header-name .logged-in-user-header {
    gap: 0px;
  }
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .language-header-name .logged-in-user-header .user-name {
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .language-header-name .logged-in-user-header svg {
  width: 24px;
  height: 18px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .language-header-name .logged-in-user-header svg path {
  stroke: #ffd90b;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .language-header-name .logged-in-user-header span svg {
  stroke: #ffd90b;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .language-header-name .logged-out-user-header svg {
  width: 18px;
  height: 18px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu {
  width: 50%;
  display: flex;
  gap: 24px;
  justify-content: end;
  align-items: center;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu {
    gap: 4px;
  }
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .icn-cart {
  width: 18px;
  height: 18px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .header-miniCart {
  top: calc(100% + 9px);
  right: 3px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  width: auto;
}
.lang-ar .c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .header-miniCart {
  left: 0px;
  right: auto;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .header-miniCart .c-b2c-cart-wrapper {
  border-top: 4px solid #ffd90b;
  border-radius: 4px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .header-miniCart .w--content {
  margin: 0;
  max-width: 100%;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper {
  width: 361px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper.hide-cart {
  width: 248px !important;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .empty-cart-container .btn-primary {
  margin: 0 24px 24px 24px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .empty-cart-container .btn-primary a {
  white-space: nowrap;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .empty-cart-container.revampheader-empty-cart-container {
  justify-content: flex-start !important;
  min-height: auto !important;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .empty-cart-container.revampheader-empty-cart-container .empty-title {
  padding: 24px 24px 0;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .empty-cart-container.revampheader-empty-cart-container .btn-primary {
  margin-top: 12px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .empty-cart-container.revampheader-empty-cart-container .btn-primary a {
  background-color: #fff;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .empty-cart-container.revampheader-empty-cart-container .btn-primary a::before {
  background-color: #ffd90b;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content {
  display: flex;
  flex-direction: column;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section {
  padding: 24px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .ticket-validity {
  margin-top: 10px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .product-details {
  padding-bottom: 20px;
}
.buy-annual-pass--page .c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section {
  display: block !important;
  border: none;
  border-bottom: 1px solid #d3d3d3;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .product-details-wrapper .ticket-subsection-container {
  padding-top: 0px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .product-details-wrapper .ticket-subsection-container .delete-btn {
  margin-top: 10px !important;
  margin-left: 10px !important;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .product-details-wrapper .ticket-subsection-container .show .ticket-sub-section {
  margin-bottom: 0px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.buy-annual-pass--page .c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container {
  border-top: none;
  padding-bottom: 0px;
  background-color: #f7f7f7;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 24px;
  border: none;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .continue-shopping-cta-text {
  border-bottom: 1px solid #ffd90b;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "GothamBlack", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .cart-summary-details {
  width: 100%;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .cart-summary-details .cart-price-container .total-price-container {
  padding: 0px;
  background-color: transparent !important;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container {
  width: 100%;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container {
  border-top: 0px;
  border-top: none;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container .checkout-button {
  padding-bottom: 0px !important;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container .checkout-button .btn-primary {
  width: calc(100% - 12px);
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .continue-shopping-cta {
  width: 100%;
  padding: 0px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container span svg {
  width: 22px;
  height: 22px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .total-price-indication {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffd90b;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .header-revamp-tooltip .header-revamp-dropdown {
  top: 32px;
}
.lang-ar .c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .header-revamp-tooltip .header-revamp-dropdown {
  left: 0px;
}
.lang-en .c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .header-revamp-tooltip .header-revamp-dropdown {
  right: 5px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .header-revamp-tooltip .header-revamp-dropdown.profile-dropdown {
  top: 35px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .header-revamp-tooltip .revamp-header-nav-link {
  color: #fff;
  font-feature-settings: "liga" off, "clig" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 1px;
  cursor: pointer;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .header-revamp-tooltip .revamp-header-nav-link.tooltipOpen svg {
  stroke: #ffd90b !important;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .header-revamp-tooltip .revamp-header-nav-link.tooltipOpen svg path {
  stroke: #ffd90b !important;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .header-revamp-tooltip .revamp-header-nav-link.tooltipOpen svg#header-dropdown-icon {
  transform: rotate(180deg);
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .search-component .container {
  width: 100%;
}
.c-header-revamp-component .revamp-header-container .revamp-header-bottom-body .header-revamp-list {
  position: relative;
  animation: slideInBottom 0.4s ease-out;
}
@keyframes slideInBottom {
  0% {
    top: 30px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
.c-header-revamp-component .revamp-header-container .revamp-header-bottom-body .header-revamp-list .arrow-icon {
  opacity: 0;
  transform: translateX(-20px);
  display: block;
}
.c-header-revamp-component .revamp-header-container .revamp-header-bottom-body .header-revamp-list:hover .arrow-icon {
  opacity: 1;
  transform: translateX(0);
  animation: slideInBounce 0.3s ease-out;
}
@keyframes slideInBounce {
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  33% {
    transform: translateX(2px);
    opacity: 0.5;
  }
  63% {
    transform: translateX(5px);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.c-header-revamp-component .revamp-header-container .revamp-header-bottom-body .header-revamp-list:hover {
  background-color: #f8f8f8;
  color: #808080;
  border-radius: 4px;
}
.c-header-revamp-component.mini-header {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header-revamp-component.mini-header {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 91;
  }
}

/* < 1023px */
@media (min-width: 320px) and (max-width: 1023px) {
  .b2c-mini-cart-wrapper.revampHeader {
    z-index: 50 !important;
  }
}
/* < 768px */
@media (min-width: 320px) and (max-width: 767px) {
  .headerRevamp .mycart-heading-revamp-header {
    background-color: #000 !important;
  }
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .continue-shopping-cta-text {
    border-bottom: 1px solid #ffd90b;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "GothamBlack", "Open Sans", Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  .minicart-no-scroll .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .mycart-heading-revamp-header .c-mini-check-icon {
    transform: translate(0px, -17px) rotate(180deg) !important;
  }
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .mycart-heading-revamp-header .c-mini-check-icon {
    /* < 1023px */
  }
}
@media (min-width: 320px) and (max-width: 767px) and (min-width: 320px) and (max-width: 1023px) {
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .mycart-heading-revamp-header .c-mini-check-icon {
    background-image: url(../../../assets/img/chevron-up.png);
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section {
    padding: 24px;
    padding-bottom: 0px;
  }
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .global-cart-error {
    margin-bottom: 20px;
  }
  .buy-annual-pass--page .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section {
    border: none;
    border-bottom: 1px solid #d3d3d3;
  }
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .ticket-validity {
    margin-top: 10px;
  }
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .product-details-wrapper .ticket-subsection-container .delete-btn {
    margin-top: 10px !important;
    margin-left: 10px !important;
  }
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .product-details-wrapper .ticket-subsection-container .show .early-bird-wrap {
    margin-bottom: 0px;
  }
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container {
    border-top: 1px solid #D3D3D3;
    background: #F8F8F8;
  }
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .btn-primary a {
    background-color: #ffd90b;
  }
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .checkout-button .btn-primary {
    /* < 1023px */
  }
}
@media (min-width: 320px) and (max-width: 767px) and (min-width: 320px) and (max-width: 1023px) {
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .checkout-button .btn-primary {
    background-color: #fff !important;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container {
    border-top: 0px;
  }
  .buy-annual-pass--page .tenant-fwad .cart-dropdown-wrap .c-b2c-mini-cart {
    height: calc(100vh - 61px) !important;
  }
}
.col-lg-4.col-md-12.col-sm-12.page-scrolled {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1023px) {
  .col-lg-4.col-md-12.col-sm-12.page-scrolled {
    top: 55px !important;
  }
  .expanded-mycart .col-lg-4.col-md-12.col-sm-12.page-scrolled {
    top: 0px !important;
  }
}

body.buy-annual-pass--page .headerRevamp .c-purchase-journey .b2c-mini-cart-wrapper {
  position: relative;
}
body.buy-annual-pass--page .tenant-fwad .cart-dropdown-wrap.headerRevamp .c-b2c-mini-cart {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1023px) {
  body.buy-annual-pass--page .tenant-fwad .cart-dropdown-wrap.headerRevamp .c-b2c-mini-cart {
    height: calc(100vh - 61px) !important;
  }
}

/* import all utilities scss */
/*
 * @ Variables
 *
*/
/*
 *  Farah Typograpy
*/
/*
 *  Farah base Font sizex
*/
/*
 * H1
*/
/* Russian */
/*Chinese*/
/*
 * H2
*/
/* Russian */
/*Chinese*/
/*
 * H3
*/
/* Russian */
/*Chinese*/
/*
/*
 * H4
*/
/*Russian*/
/*Chinese*/
/*
 * H5
*/
/* Russian */
/*Chinese*/
/*
 * H6
*/
/* Russian */
/*Chinese*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 *  Base Container
*/
/*
 *  Z-index Of components
*/
/* Please do not give z-index more than these z-Index */
/*
 *  Farah desktop font Sizes
*/
/*
 *  Farah mobile font Sizes
*/
/*
 *  Radius
*/
/*
 *  Use Defaults
*/
/*
 *  Base Alignments
*/
/*Hero Map component*/
/*input range*/
/**
    New Summary Cart
*/
/*
 * New Design System - 2025
 *
*/
/* Colors */
/* footer */
/* Editoiral Grid */
/*Carousel with Preview*/
/*
*  My profile
*/
/*
 *  Generic Colors
*/
/*
 *  Farah Base primary colors
*/
/*
 *  Farah Supporting colors
*/
/*
 *  Yas WaterWorld Base primary colors
*/
/*
 *  Yas Island Base primary colors
*/
/*
 *  Yas Ferrari World Base primary colors
*/
/*
 * Farah Font/buttons Color
*/
/*
 * Farah Font/buttons Color
*/
/**
Hotel Booking
*/
/**
tab with placeholder
*/
/**
tab feature list
*/
/*media carousel overlay*/
/*calendar dropdown*/
/* Trip Advisor Color */
/*overlay*/
/* Flash-Sale */
/*
* form placeholder color variables
*/
/*
 * @ Mixins
 *
*/
/*
* Background Image
*/
/*
* Configure vendor
*/
/*
* Line height font
*/
/*
* Clearfix
*/
/*
* Text Nowrap
*/
/*
* Font Smoothing
*/
/*
* Transition
  usage example
    a{
    color: gray;
    @include transition(color .3s ease);
    &:hover {
      color: black;
    }
}
*/
/*
* Background Gradient
*/
/*
* Background Horizontal
*/
/*
* Background Radial
*/
/*
* Background Size
*/
/*
* Background Color Opacity
*/
/*
* Border Radius
*/
/*
* Border Radius Separate
*/
/*
* Box
*/
/*
* Box Rgba
*/
/*
* Box Shadow
*/
/*
* Box Sizing
*/
/*
* Columns
*/
/*
* Flex
*/
/*
* Flip
*/
/*
* Opacity
*/
/*
* Resize
*/
/*
* Rotate
*/
/*
* Text Shadow
*/
/*
* Transform
*/
/*
* Keyframes
 Usage Example
  @include keyframes(slide-down) {
    0% { opacity: 1; }
    90% { opacity: 0; }
  }
*/
/*
* Animation
Usage Example
 .element {
    @include animation('slide-down 5s 3');
  }
*/
/*
* Widescreen
*/
/*
* Desktop (PC's)
*/
/*
* Desktop (PC's)
*/
/*
* Wide screen Desktop (PC's)
*/
/*
  * large screen Desktop (PC's)
  */
/*
* Tablet
*/
/*
  * TabletOnly
  */
/*
* Mobile
*/
/*
* upto Tablet
*/
/*
* No Screen
*/
/**
**  how to Use Mixin for tablet-landscape
**
.example{
  left: 35px;
  @include tablet-landscape{
      left: 100px;
  }
}
**/
/**
*   Use Arrow inside the :after and :before pseudo elements
*   and change the position as per needed
*   @include arrows('right', 7px, 7px, #000);
**/
/*
* Font Face
*/
/*
* Placeholder Styling
*/
/*half circle left right for ticket tiles*/
/**
* Desc: Create equilateral triangle used in header on hover and focus
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * @ Text
 *
*/
/* lang ar */
/* lang ru */
/**
* Heading 5 has two versions
* Bold and Black
* Use heading-ru-5-bold and heading-ru-5-black
**/
/**
* Heading 6 has two versions
* Bold and Black
* Use heading-ru-6-bold and heading-ru-6-black
**/
/* lang zh */
/**
* Heading 5 has two versions
* Bold and Black
* Use heading-zh-5-bold and heading-zh-5-black
**/
/**
* Heading 6 has two versions
* Bold and Black
* Use heading-zh-6-bold and heading-zh-6-black
**/
/* lang all */
:root {
  --font-weight-base: 400;
}

body.lang-ar {
  --font-weight-base: 700;
}

/*
 * @ Gradients
 *
*/
/*
 * Line height font
 */
/* < 768px */
@media (min-width: 320px) and (max-width: 767px) {
  .tenant-wbw .revamp-header-notice-component {
    display: flex;
    padding: 12px 20px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
  .tenant-wbw .revamp-header-notice-component.c-cookies {
    z-index: unset;
  }
  .tenant-wbw .revamp-header-notice-component .revamp-header-note {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #000;
    font-feature-settings: "liga" off, "clig" off;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.1px;
  }
  .tenant-wbw .revamp-header-notice-component span svg {
    width: 25px;
    height: 24px;
  }
  .tenant-wbw .revamp-header-notice-component .close-icon {
    width: 24px;
    height: 24px;
  }
  .tenant-wbw .revamp-header-notice-component.component {
    margin-bottom: 0px;
  }
}
.c-header-revamp-component .revamp-header-notice-component {
  display: flex;
  padding: 12px 35px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.c-header-revamp-component .revamp-header-notice-component span svg {
  width: 25px;
  height: 24px;
}
.c-header-revamp-component .revamp-header-notice-component p {
  color: #000;
  font-weight: 400;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0px;
}
.c-header-revamp-component .revamp-header-notice-component p a {
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
}
.c-header-revamp-component .revamp-header-notice-component p a:hover {
  color: inherit;
}
.c-header-revamp-component .revamp-header-notice-component .revamp-header-note {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 8px;
}
.c-header-revamp-component .revamp-header-notice-component .revamp-header-note p {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.c-header-revamp-component .revamp-header-notice-component .close-icon {
  width: 25px;
  height: 24px;
}
.c-header-revamp-component .revamp-header-top {
  background-color: #000;
}
.c-header-revamp-component .revamp-header-top.mini-header {
  background-color: #000 !important;
}
.c-header-revamp-component .revamp-header-top .revamp-header-openinghours-container .revamp-header-openinghours {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;
}
.c-header-revamp-component .revamp-header-top .revamp-header-openinghours-container .revamp-header-openinghours .right-chevron-icon {
  height: 20px;
  width: 20px;
}
.lang-ar .c-header-revamp-component .revamp-header-top .revamp-header-openinghours-container .revamp-header-openinghours .right-chevron-icon {
  transform: rotate(180deg);
}
.c-header-revamp-component .revamp-header-top .revamp-header-openinghours-container .revamp-header-openinghours h4 {
  color: #fff;
  text-transform: uppercase;
  font-family: "GothamBold", "Open Sans", Arial, sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 2px;
}
.lang-ar .c-header-revamp-component .revamp-header-top .revamp-header-openinghours-container .revamp-header-openinghours h4 {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  letter-spacing: 0px;
}
.c-header-revamp-component .revamp-header-bottom {
  background-color: #333333;
  padding: 10px 35px !important;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-nav-wrapper .revamp-header-nav-link {
  cursor: pointer;
  color: #fff;
  font-family: "GothamBlack", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 14px;
  font-weight: 500;
  font-style: normal;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-nav-wrapper .revamp-header-nav-link:hover {
  color: #FFEC00;
}
.lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-nav-wrapper .revamp-header-nav-link {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  letter-spacing: 0px;
  font-size: 16px;
  font-weight: 700;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn {
  display: inline-block;
  position: relative;
  z-index: 1;
  /* Medium devices (desktops, 1024px and up) */
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button {
  padding: 0;
  border-style: none;
  line-height: 1.6;
  letter-spacing: 1.9px;
  text-transform: uppercase;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn.reverse a:before,
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn.reverse button:before {
  border: solid 2px #fff;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn .header-bottom a::before, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn .header-bottom button::before {
  top: 8px;
  left: 8px;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a,
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button {
  position: relative;
  -webkit-border-radius: 6px;
  -khtml-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px;
  background-color: #ffdf00;
  color: #000;
  border: solid 2px #000;
  padding: 7px 20px;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.83;
  letter-spacing: 2px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  text-align: center;
  margin: 0 9px 9px 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a,
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a,
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a,
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.lang-ru .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a,
.lang-ru .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button {
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 2px;
  line-height: 14px;
  font-family: "NotoSansBold", "Open Sans", Arial, sans-serif;
  font-weight: bold;
}
.lang-zh .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a,
.lang-zh .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button {
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 2px;
  line-height: 14px;
  font-family: "PingFang SC", "STHeiti", "华文黑体", "Microsoft YaHei", "微软雅黑", "GothamBold", "Open Sans", Arial, sans-serif;
  font-weight: bold;
  font-weight: bold;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a:before,
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button:before {
  content: " ";
  position: absolute;
  top: 9px;
  left: 9px;
  -webkit-border-radius: 4px;
  -khtml-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  background-color: transparent;
  border: solid 2px #000;
  width: 100%;
  height: 100%;
  z-index: -1;
}
@media only screen and (min-width: 1024px) {
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a:hover,
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button:hover {
    background-color: #fff;
    top: 3px;
    left: 3px;
    border: 2px solid #333;
  }
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a:hover:before,
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button:hover:before {
    background-color: #ffdf00;
    top: 6px;
    left: 6px;
  }
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a:hover:after,
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button:hover:after {
    content: "";
    position: absolute;
    top: -10px;
    right: 0;
    width: 100%;
    height: 20px;
  }
  .lang-zh .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a, .lang-ru .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a,
  .lang-zh .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button,
  .lang-ru .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button {
    padding: 10px 20px;
  }
}
.lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a,
.lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0;
  margin: 0 0 9px 9px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a,
  .lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button {
    letter-spacing: 0;
  }
}
.lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a:before,
.lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button:before {
  right: 9px;
}
.lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a:focus,
.lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button:focus {
  right: 0;
}
.lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a:focus:before,
.lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button:focus:before {
  right: 9px;
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a:hover,
  .lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button:hover {
    right: 3px;
  }
  .lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a:hover:before,
  .lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn button:hover:before {
    right: 6px;
  }
}
.lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn.c-annual-pass-add-cart, .lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn.button-wrapper {
  padding-left: 10px;
  padding-right: 10px;
}
.lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn .secondary-cta {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a {
  letter-spacing: 1px;
  line-height: 22px;
  padding: 3px 20px;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a:before {
  border: solid 2px #fff;
  top: 7px;
  left: 7px;
}
@media only screen and (min-width: 1024px) {
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn {
    margin-right: 7px;
  }
  .lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn {
    margin-left: 7px;
    margin-right: 0px;
  }
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-top-logo {
  position: absolute;
  z-index: 1000;
  bottom: 12px;
  left: 20px;
  /* Medium devices (desktops, 1024px and up) */
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-top-logo a,
.c-header-revamp-component .revamp-header-bottom .revamp-header-top-logo a img {
  height: 100%;
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-top-logo {
    left: unset;
    right: 20px;
  }
}

.humburger-menu-open {
  position: relative;
}
.humburger-menu-open:not(.lang-ar) {
  animation: bodySlideLeftToRight 0.2s ease-in-out;
}
@keyframes bodySlideLeftToRight {
  from {
    left: 0;
  }
  to {
    left: 200px;
  }
}
.humburger-menu-open.lang-ar {
  animation: bodySlideRightToLeft 0.2s ease-in-out;
}
@keyframes bodySlideRightToLeft {
  from {
    right: 0;
  }
  to {
    right: 200px;
  }
}

.humburger-menu-close {
  position: relative;
}
.humburger-menu-close:not(.lang-ar) {
  animation: bodySlideCloseLeftToRight 0.2s ease-in-out;
}
@keyframes bodySlideCloseLeftToRight {
  from {
    left: 200px;
  }
  to {
    left: 0;
  }
}
.humburger-menu-close.lang-ar {
  animation: bodySlideCloseRightToLeft 0.2s ease-in-out;
}
@keyframes bodySlideCloseRightToLeft {
  from {
    right: 200px;
  }
  to {
    right: 0;
  }
}

/* < 768px */
@media (min-width: 320px) and (max-width: 767px) {
  .slider-close-button {
    position: absolute;
    top: 20px;
    z-index: 13;
    right: 20px;
  }
  .slider-close-button svg {
    width: 26px !important;
    height: 24px !important;
  }
  .lang-ar .slider-close-button {
    left: 20px;
    right: auto;
  }
  .has-sub-menu .slider-close-button {
    position: fixed;
  }
  .c-header-revamp-component {
    display: flex;
    height: 60px;
    padding: 10px 20px;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    background: #000;
    flex-shrink: 0;
    /* < 768px */
  }
}
@media (min-width: 320px) and (max-width: 767px) and (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component {
    height: 56px;
    background-color: #333333;
    padding: 11px 20px;
  }
  .c-header-revamp-component.mini-header {
    background-color: #000;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component svg {
    width: 24px;
    height: 20px;
  }
  .c-header-revamp-component picture {
    width: 35px;
    height: unset;
  }
  .c-header-revamp-component.mobile {
    z-index: 91;
  }
  .c-header-revamp-component.mobile .dark-logo {
    position: absolute;
    left: 50%;
    transform: translate(-50px, 0px);
  }
  .c-header-revamp-component.mobile .hamburger-slider {
    background-color: #000;
  }
  .c-header-revamp-component.mobile .hamburger-slider .dark-logo {
    position: initial;
    transform: none;
  }
  .c-header-revamp-component .revamp-header-btn {
    font-family: "GothamBlack", "Open Sans", Arial, sans-serif;
    font-weight: 400;
    font: normal normal 400 12px "GothamBold", "Open Sans", Arial, sans-serif;
    letter-spacing: 0;
    color: #000;
    text-transform: uppercase;
    text-decoration: none;
    /* Small devices (tablets, 768px and up) */
    padding: 8px;
    transform: none;
    max-height: 36px;
    font-size: 12px;
    line-height: 22px;
    font-weight: 500;
    letter-spacing: 1px;
    /* Medium devices (desktops, 1024px and up) */
  }
  .c-header-revamp-component .revamp-header-btn button {
    padding: 0;
    border-style: none;
    line-height: 1.6;
    letter-spacing: 1.9px;
    text-transform: uppercase;
  }
}
@media (min-width: 320px) and (max-width: 767px) and (min-width: 768px) {
  .c-header-revamp-component .revamp-header-btn {
    font-size: 13px;
    letter-spacing: 0;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component .revamp-header-btn a {
    transform: none;
    font-size: 12px;
    text-decoration: none;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) and (min-width: 1024px) {
  .c-header-revamp-component .revamp-header-btn {
    margin-right: 7px;
  }
  .lang-ar .c-header-revamp-component .revamp-header-btn {
    margin-left: 7px;
    margin-right: 0px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .revamp-header-btn {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    background: #ffd90b;
  }
  .hamburger-menu {
    height: 100vh;
    width: 100vw;
    background-color: #333333;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 11;
  }
  body:not(.lang-ar) .hamburger-menu.opening {
    animation: openLeftToRight-english 0.2s linear;
  }
  @keyframes openLeftToRight-english {
    from {
      left: -100%;
    }
    to {
      left: 0%;
    }
  }
  .lang-ar .hamburger-menu.opening {
    animation: openRightToLeft-english 0.2s linear;
  }
  @keyframes openRightToLeft-english {
    from {
      right: -100%;
    }
    to {
      right: 0%;
    }
  }
  body:not(.lang-ar) .hamburger-menu.has-sub-menu, body:not(.lang-ar) .hamburger-menu.closing, body:not(.lang-ar) .hamburger-menu.has-sub-menu.closing .slider-content.open, body:not(.lang-ar) .hamburger-menu.has-sub-menu.closing .search-box-slider.open {
    left: -100%;
    animation: closeRightToLeft-english 0.2s linear;
  }
  @keyframes closeRightToLeft-english {
    from {
      left: 0%;
    }
    to {
      left: -100%;
    }
  }
  .lang-ar .hamburger-menu.has-sub-menu, .lang-ar .hamburger-menu.closing, .lang-ar .hamburger-menu.has-sub-menu.closing .slider-content.open, .lang-ar .hamburger-menu.has-sub-menu.closing .search-box-slider.open {
    right: -100%;
    animation: closeLeftToRight-english 0.2s linear;
  }
  @keyframes closeLeftToRight-english {
    from {
      right: 0%;
    }
    to {
      right: -100%;
    }
  }
  .hamburger-menu .slider-name {
    width: 100%;
    display: flex;
    padding: 14px 20px;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    align-self: stretch;
    color: #fff;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "GothamBlack", "Open Sans", Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  .hamburger-menu .slider-name .profile-title {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #ffd90b;
    font-weight: 400;
    font-family: "Open Sans", Arial, sans-serif;
    text-transform: capitalize;
    letter-spacing: 0px;
  }
  .hamburger-menu .slider-name .profile-title svg {
    margin-top: 5px;
  }
  .lang-ar .hamburger-menu .slider-name .profile-title svg {
    transform: rotate(0deg);
  }
  .lang-ar .hamburger-menu .slider-name .profile-title {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
    font-weight: 700;
  }
  .hamburger-menu .slider-name .profile-title .profile-title svg {
    margin-top: 2px;
  }
  .hamburger-menu .slider-name .profile-title .non-loggedin-placeholder {
    margin-top: 5px;
    color: white;
  }
  .lang-ar .hamburger-menu .slider-name {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
    letter-spacing: 0px;
    font-weight: 700;
  }
  .lang-ar .hamburger-menu .slider-name svg {
    transform: rotate(180deg);
  }
  .hamburger-menu .slider-name.profile-title {
    padding: 11px 20px 11px 16px;
  }
  .lang-ar .hamburger-menu .slider-name.profile-title {
    padding: 11px 20px 11px 19px;
  }
  .lang-ar .hamburger-menu .slider-name {
    font-weight: 700;
  }
  .hamburger-menu .slider-content {
    width: 100vw;
    height: 100vh;
    background-color: #333333;
    position: fixed;
    z-index: 12;
    top: 0px;
    right: 0px;
  }
  body:not(.lang-ar) .hamburger-menu .slider-content.open {
    right: 0%;
    animation: openRightToLeft-english 0.2s linear;
  }
  @keyframes openRightToLeft-english {
    from {
      right: -100%;
    }
    to {
      right: 0%;
    }
  }
  .lang-ar .hamburger-menu .slider-content.open {
    left: 0%;
    right: auto;
    animation: openLeftToRight-english 0.2s linear;
  }
  @keyframes openLeftToRight-english {
    from {
      left: -100%;
    }
    to {
      left: 0%;
    }
  }
  .hamburger-menu .slider-content.open .slider-header {
    background-color: #000;
  }
  body:not(.lang-ar) .hamburger-menu .slider-content.close {
    right: -100%;
    animation: closeLeftToRight-english 0.2s linear;
  }
  @keyframes closeLeftToRight-english {
    from {
      right: 0%;
    }
    to {
      right: -100%;
    }
  }
  .lang-ar .hamburger-menu .slider-content.close {
    left: -100%;
    right: auto;
    animation: closeRightToLeft-english 0.2s linear;
  }
  @keyframes closeRightToLeft-english {
    from {
      left: 0%;
    }
    to {
      left: -100%;
    }
  }
  .hamburger-menu .slider-content .profile-slider {
    background-color: #333333;
  }
  .hamburger-menu .slider-content .slider-header {
    display: flex;
    height: 60px;
    padding: 12px 20px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    background-color: #333333;
  }
  .lang-ar .hamburger-menu .slider-content .slider-header span svg {
    transform: rotate(180deg);
  }
  .hamburger-menu .slider-content .slider-title {
    font-family: "GothamBlack", "Open Sans", Arial, sans-serif;
    font-weight: 400;
    display: flex;
    padding: 15px 20px;
    margin: 16px 0px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    color: #fff;
    font-feature-settings: "liga" off, "clig" off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
    border-bottom: 0.5px solid #d3d3d3;
  }
  .lang-ar .hamburger-menu .slider-content .slider-title {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  }
  .hamburger-menu .slider-content .slider-title .profile-title {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #ffd90b;
    font-weight: 400;
    font-family: "Open Sans", Arial, sans-serif;
    text-transform: capitalize;
    letter-spacing: 0px;
    font-weight: 700;
  }
  .lang-ar .hamburger-menu .slider-content .slider-title .profile-title {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  }
  .hamburger-menu .slider-content .slider-title .profile-title svg {
    margin-top: 2px;
  }
  .hamburger-menu .slider-content .slider-list li {
    display: flex;
    padding: 14px 40px;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    align-self: stretch;
    color: #fff;
    font-feature-settings: "liga" off, "clig" off;
    font-weight: 400;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-transform: capitalize;
  }
  .hamburger-menu .slider-content .slider-list li a {
    color: #fff;
  }
  .hamburger-menu .slider-content .slider-list li:hover {
    background-color: #333333;
  }
  .lang-ar .hamburger-menu .slider-content .slider-list li {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  }
  .hamburger-menu .slider-content .slider-list li .helper-upcoming {
    display: flex;
    padding: 0px 4px 1px 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background-color: #FFDF00;
    color: #000;
    font-size: 10px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
  }
  .hamburger-menu .mb-nav-links-bottom {
    border-top: 1px solid #333333;
  }
  .hamburger-menu .mb-nav-links-bottom .mb-language-selector-container {
    color: #fff;
    letter-spacing: normal;
    line-height: 26px;
  }
  .hamburger-menu .mb-nav-links-bottom .language-selector-capsule {
    border: 1px solid #d3d3d3;
  }
  .hamburger-menu .mb-nav-links-bottom .language-selector-capsule.selected {
    color: #000;
    background: #fff;
  }
  .hamburger-slider {
    padding-bottom: 16px;
  }
  .hamburger-slider .hamburger-slider-header {
    padding: 12px 20px;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .hamburger-slider .hamburger-slider-header.opening-hour-div {
    padding: 14px 20px;
    width: 100%;
  }
  .lang-ar .hamburger-slider .hamburger-slider-header svg {
    transform: rotate(180deg);
  }
  .hamburger-slider .hamburger-slider-header .openinghours {
    width: 100%;
  }
  .hamburger-slider .hamburger-slider-header .openinghours .revamp-header-openinghours {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .hamburger-slider .hamburger-slider-header .openinghours .revamp-header-openinghours h4 {
    color: #fff;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "GothamBlack", "Open Sans", Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  .lang-ar .hamburger-slider .hamburger-slider-header .openinghours .revamp-header-openinghours h4 {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
    letter-spacing: 0px;
  }
  .hamburger-slider .hamburger-slider-header .searchBox {
    display: flex;
    gap: 4px;
    justify-content: flex-start;
    align-items: center;
  }
  .hamburger-slider .hamburger-slider-header .searchBox p {
    color: #fff;
    font-feature-settings: "liga" off, "clig" off;
    font-weight: 400;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.1px;
  }
  .lang-ar .hamburger-slider .hamburger-slider-header .searchBox p {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
    font-weight: 700;
  }
  .lang-ar .hamburger-slider .hamburger-slider-header .searchBox {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  }
  .lang-ar .hamburger-slider .hamburger-slider-header .searchBox svg {
    transform: rotate(0deg);
  }
  .hamburger-slider .hamburger-slider-header.searchBox {
    padding: 11px 16px;
  }
  .hamburger-slider .hamburger-slider-header picture {
    width: 40px;
    left: 16px;
    top: 24px;
    height: 100%;
  }
  .lang-ar .hamburger-slider .hamburger-slider-header picture {
    right: -10px;
    left: unset;
  }
  .hamburger-slider .search-box-slider {
    width: 100vw;
    height: 100vh;
    background-color: #333333;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 12;
  }
  body:not(.lang-ar) .hamburger-slider .search-box-slider.open {
    right: 0%;
    animation: openRightToLeft-english 0.2s linear;
  }
  @keyframes openRightToLeft-english {
    from {
      right: -100%;
    }
    to {
      right: 0%;
    }
  }
  .lang-ar .hamburger-slider .search-box-slider.open {
    left: 0%;
    right: auto;
    animation: openLeftToRight-english 0.2s linear;
  }
  @keyframes openLeftToRight-english {
    from {
      left: -100%;
    }
    to {
      left: 0%;
    }
  }
  body:not(.lang-ar) .hamburger-slider .search-box-slider.close {
    right: -100%;
    animation: closeLeftToRight-english 0.2s linear;
  }
  @keyframes closeLeftToRight-english {
    from {
      right: 0%;
    }
    to {
      right: -100%;
    }
  }
  .lang-ar .hamburger-slider .search-box-slider.close {
    left: -100%;
    right: auto;
    animation: closeRightToLeft-english 0.2s linear;
  }
  @keyframes closeRightToLeft-english {
    from {
      left: 0%;
    }
    to {
      left: -100%;
    }
  }
  .hamburger-slider .search-box-slider .header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #000;
  }
  .hamburger-slider .search-box-slider .header .search-header {
    display: flex;
    height: 60px;
    padding: 12px 20px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
  }
  .hamburger-slider .search-box-slider .header .search-header span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lang-ar .hamburger-slider .search-box-slider .header .search-header span svg {
    transform: rotate(180deg);
  }
  .hamburger-slider .search-box-slider .header .search-input {
    display: flex;
    height: 48px;
    padding: 11px 20px;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-bottom: 0.5px solid #d3d3d3;
  }
  .hamburger-slider .search-box-slider .header .search-input input {
    background: transparent;
    width: 100%;
    color: #fff;
    font-feature-settings: "liga" off, "clig" off;
    font-weight: 400;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0px;
  }
  .lang-ar .hamburger-slider .search-box-slider .header .search-input input {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  }
  .hamburger-slider .search-box-slider .header .search-input input::placeholder {
    color: #fff;
  }
  .hamburger-slider .search-box-slider .header .search-input svg path {
    stroke: #fff;
  }
  .hamburger-slider .search-box-slider .popular-search {
    gap: 0px;
    padding-top: 16px;
  }
  .hamburger-slider .search-box-slider .popular-search.suggestion {
    background-color: #f8f8f8;
    color: #000;
    height: fit-content;
  }
  .hamburger-slider .search-box-slider .popular-search.suggestion .search-list {
    height: fit-content;
  }
  .hamburger-slider .search-box-slider .popular-search.suggestion .search-list .list-item {
    cursor: pointer;
  }
  .hamburger-slider .search-box-slider .popular-search.suggestion .search-list .list-item .list-item-box svg path {
    stroke: #000;
  }
  .hamburger-slider .search-box-slider .popular-search.suggestion .search-list .list-item .list-item-text {
    color: #000;
    font-weight: 400;
  }
  .hamburger-slider .search-box-slider .popular-search .popular-title {
    display: flex;
    height: 48px;
    padding: 11px 40px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    font-weight: 400;
    font-family: "Open Sans", Arial, sans-serif;
    color: #fff;
  }
  .hamburger-slider .search-box-slider .popular-search .popular-title svg {
    margin-top: 5px;
  }
  .lang-ar .hamburger-slider .search-box-slider .popular-search .popular-title {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  }
  .hamburger-slider .search-box-slider .popular-search .search-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    align-self: stretch;
    height: fit-content;
  }
  .hamburger-slider .search-box-slider .popular-search .search-list .list-item {
    display: flex;
    height: 48px;
    padding: 11px 40px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }
  .hamburger-slider .search-box-slider .popular-search .search-list .list-item .list-item-box {
    color: #fff;
    font-feature-settings: "liga" off, "clig" off;
    font-weight: 400;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0px;
  }
  .lang-ar .hamburger-slider .search-box-slider .popular-search .search-list .list-item .list-item-box {
    transform: rotate(180deg);
  }
  .hamburger-slider .search-box-slider .popular-search .search-list .list-item .list-item-box svg path {
    stroke: #fff;
  }
  .hamburger-slider .search-box-slider .popular-search .search-list .list-item .list-item-text {
    color: #fff;
    font-feature-settings: "liga" off, "clig" off;
    font-weight: 400;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    line-height: 26px;
    letter-spacing: 0px;
  }
  .lang-ar .hamburger-slider .search-box-slider .popular-search .search-list .list-item .list-item-text {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  }
  .navlinkDiv {
    margin-top: 16px;
    margin-bottom: 16px;
    /* < 1023px */
  }
}
@media (min-width: 320px) and (max-width: 767px) and (min-width: 320px) and (max-width: 1023px) {
  .navlinkDiv {
    overflow-y: scroll;
    max-height: 45vh;
  }
  .navlinkDiv .navlinkDiv ul {
    list-style-type: none;
    /* Removes bullet points */
    margin: 0;
    /* Removes default margin */
    padding: 0;
    /* Removes default padding */
  }
}
.revamp-header-notice-component.mobile {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .revamp-header-notice-component.mobile {
    display: none;
  }
}

.article-component.farah-main .article-list .article-list-text .v-dynamic-product-pricing h3 {
  color: #000;
}

.article-component.farah-main .article-list .article-list-text .v-dynamic-product-pricing p {
  text-align: center;
}

/* import all utilities scss */
/*
 * @ Variables
 *
*/
/*
 *  Farah Typograpy
*/
/*
 *  Farah base Font sizex
*/
/*
 * H1
*/
/* Russian */
/*Chinese*/
/*
 * H2
*/
/* Russian */
/*Chinese*/
/*
 * H3
*/
/* Russian */
/*Chinese*/
/*
/*
 * H4
*/
/*Russian*/
/*Chinese*/
/*
 * H5
*/
/* Russian */
/*Chinese*/
/*
 * H6
*/
/* Russian */
/*Chinese*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 *  Base Container
*/
/*
 *  Z-index Of components
*/
/* Please do not give z-index more than these z-Index */
/*
 *  Farah desktop font Sizes
*/
/*
 *  Farah mobile font Sizes
*/
/*
 *  Radius
*/
/*
 *  Use Defaults
*/
/*
 *  Base Alignments
*/
/*Hero Map component*/
/*input range*/
/**
    New Summary Cart
*/
/*
 * New Design System - 2025
 *
*/
/* Colors */
/* footer */
/* Editoiral Grid */
/*Carousel with Preview*/
/*
*  My profile
*/
/*
 *  Generic Colors
*/
/*
 *  Farah Base primary colors
*/
/*
 *  Farah Supporting colors
*/
/*
 *  Yas WaterWorld Base primary colors
*/
/*
 *  Yas Island Base primary colors
*/
/*
 *  Yas Ferrari World Base primary colors
*/
/*
 * Farah Font/buttons Color
*/
/*
 * Farah Font/buttons Color
*/
/**
Hotel Booking
*/
/**
tab with placeholder
*/
/**
tab feature list
*/
/*media carousel overlay*/
/*calendar dropdown*/
/* Trip Advisor Color */
/*overlay*/
/* Flash-Sale */
/*
* form placeholder color variables
*/
/*
 * @ Mixins
 *
*/
/*
* Background Image
*/
/*
* Configure vendor
*/
/*
* Line height font
*/
/*
* Clearfix
*/
/*
* Text Nowrap
*/
/*
* Font Smoothing
*/
/*
* Transition
  usage example
    a{
    color: gray;
    @include transition(color .3s ease);
    &:hover {
      color: black;
    }
}
*/
/*
* Background Gradient
*/
/*
* Background Horizontal
*/
/*
* Background Radial
*/
/*
* Background Size
*/
/*
* Background Color Opacity
*/
/*
* Border Radius
*/
/*
* Border Radius Separate
*/
/*
* Box
*/
/*
* Box Rgba
*/
/*
* Box Shadow
*/
/*
* Box Sizing
*/
/*
* Columns
*/
/*
* Flex
*/
/*
* Flip
*/
/*
* Opacity
*/
/*
* Resize
*/
/*
* Rotate
*/
/*
* Text Shadow
*/
/*
* Transform
*/
/*
* Keyframes
 Usage Example
  @include keyframes(slide-down) {
    0% { opacity: 1; }
    90% { opacity: 0; }
  }
*/
/*
* Animation
Usage Example
 .element {
    @include animation('slide-down 5s 3');
  }
*/
/*
* Widescreen
*/
/*
* Desktop (PC's)
*/
/*
* Desktop (PC's)
*/
/*
* Wide screen Desktop (PC's)
*/
/*
  * large screen Desktop (PC's)
  */
/*
* Tablet
*/
/*
  * TabletOnly
  */
/*
* Mobile
*/
/*
* upto Tablet
*/
/*
* No Screen
*/
/**
**  how to Use Mixin for tablet-landscape
**
.example{
  left: 35px;
  @include tablet-landscape{
      left: 100px;
  }
}
**/
/**
*   Use Arrow inside the :after and :before pseudo elements
*   and change the position as per needed
*   @include arrows('right', 7px, 7px, #000);
**/
/*
* Font Face
*/
/*
* Placeholder Styling
*/
/*half circle left right for ticket tiles*/
/**
* Desc: Create equilateral triangle used in header on hover and focus
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * @ Text
 *
*/
/* lang ar */
/* lang ru */
/**
* Heading 5 has two versions
* Bold and Black
* Use heading-ru-5-bold and heading-ru-5-black
**/
/**
* Heading 6 has two versions
* Bold and Black
* Use heading-ru-6-bold and heading-ru-6-black
**/
/* lang zh */
/**
* Heading 5 has two versions
* Bold and Black
* Use heading-zh-5-bold and heading-zh-5-black
**/
/**
* Heading 6 has two versions
* Bold and Black
* Use heading-zh-6-bold and heading-zh-6-black
**/
/* lang all */
:root {
  --font-weight-base: 400;
}

body.lang-ar {
  --font-weight-base: 700;
}

/*
 * @ Gradients
 *
*/
/*
 * Line height font
 */
.c-header-revamp-component .container {
  width: 100vw;
  height: 336px;
  padding: 32px 10px;
  background: #f8f8f8;
  box-shadow: 0px 1px 0px #d3d3d3;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 35px;
  left: 0px;
  z-index: 1000;
}
.c-header-revamp-component .relative-box {
  width: 24px;
  height: 24px;
  position: relative;
}
.c-header-revamp-component .relative-box-inner {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0.69px;
  top: 12px;
  transform: rotate(-45deg);
  transform-origin: 0 0;
  border: 1.5px solid #808080;
}
.c-header-revamp-component .content {
  height: 272px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
}
.c-header-revamp-component .image-container {
  width: 672px;
  height: 52px;
  border: 1px solid #d3d3d3;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  gap: 8px;
}
.c-header-revamp-component .image-container svg {
  width: fit-content;
}
.c-header-revamp-component .image-container input {
  align-items: center;
  width: 100%;
  background-color: transparent;
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0px;
}
.lang-ar .c-header-revamp-component .image-container input {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.c-header-revamp-component .image-container .autocomplete-wrapper {
  width: 100%;
}
.c-header-revamp-component .image-container .autocomplete-wrapper form {
  border: none;
}
.c-header-revamp-component .image-container .autocomplete-wrapper form .btn-reset {
  right: 0;
  position: absolute;
  padding-left: 0px;
  padding-right: 0px;
  top: 5px;
}
.c-header-revamp-component .image-container .autocomplete-wrapper form .btn-reset svg {
  width: 12px;
  height: 12px;
  fill: #d0021b;
}
.c-header-revamp-component .image-container .autocomplete-wrapper form .search-trigger {
  display: none;
}
.c-header-revamp-component .image-container.searchEnabled {
  border: 1px solid #333;
}
.c-header-revamp-component .image {
  width: 672px;
  height: 52px;
  position: absolute;
  border: 1px solid #d3d3d3;
}
.c-header-revamp-component .search-bar {
  position: absolute;
  left: 30px;
  top: 13px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.c-header-revamp-component .search-box {
  width: 24px;
  height: 24px;
  position: relative;
}
.c-header-revamp-component .search-box-inner {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 3.9px;
  top: 3.74px;
  border: 1.5px solid #808080;
}
.c-header-revamp-component .search-text {
  color: #808080;
  font-size: 16px;
  font-weight: 400;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 700;
  line-height: 26px;
  word-wrap: break-word;
}
.c-header-revamp-component .popular-search {
  align-self: stretch;
  height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
}
.c-header-revamp-component .popular-title {
  align-self: stretch;
  color: #808080;
  font-size: 16px;
  font-weight: 400;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 26px;
  word-wrap: break-word;
  letter-spacing: 0px;
}
.lang-ar .c-header-revamp-component .popular-title {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.c-header-revamp-component .search-list {
  align-self: stretch;
  height: 152px;
  padding-left: 1px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
}
.lang-ar .c-header-revamp-component .search-list {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.c-header-revamp-component .search-list.popular-search-term .list-item .list-item-text {
  font-size: 16px;
  line-height: 30px;
  font-weight: 700;
  letter-spacing: 0.1px;
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1023px) {
  .c-header-revamp-component .search-list.popular-search-term .list-item .list-item-text {
    font-weight: 700 !important;
    line-height: 28px !important;
  }
}
.c-header-revamp-component .list-item {
  cursor: pointer;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.c-header-revamp-component .list-item-box {
  width: 20px;
  height: 20px;
  position: relative;
}
.lang-ar .c-header-revamp-component .list-item-box {
  transform: rotate(180deg);
}
.c-header-revamp-component .list-item-box-inner {
  width: 13.33px;
  height: 10.67px;
  position: absolute;
  left: 3.33px;
  top: 4.67px;
  border: 1.5px solid #333333;
}
.c-header-revamp-component .list-item-text {
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  font-family: "Open Sans", Arial, sans-serif;
  line-height: 26px;
  word-wrap: break-word;
  font-feature-settings: "liga" off, "clig" off;
  font-style: normal;
  letter-spacing: 0px;
}
.lang-ar .c-header-revamp-component .list-item-text {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.c-header-revamp-component .search-close {
  position: absolute;
  right: 36px;
  top: 20.4px;
  cursor: pointer;
  width: 24px;
  height: 25px;
}
.lang-ar .c-header-revamp-component .search-close {
  left: 36px;
  right: auto;
}
.c-header-revamp-component .content .image-container svg {
  width: 25px;
  height: 24px;
  cursor: pointer;
}
.c-header-revamp-component .content .image-container input::placeholder {
  color: #7E7E7E;
}
.c-header-revamp-component .content .image-container .search-icon-desktop-wrap.enableSearch {
  cursor: pointer;
}
.c-header-revamp-component .search-component-icon {
  cursor: pointer;
}
.c-header-revamp-component .search-component-icon svg {
  width: 22px;
  height: 22px;
}
.c-header-revamp-component .search-component .container {
  z-index: 999;
}
.c-header-revamp-component .search-component .container .search-close {
  cursor: pointer;
}
.c-header-revamp-component .search-component .container .content .image-container .search-close-button {
  cursor: pointer;
}
.c-header-revamp-component .clear-search-button {
  cursor: pointer;
}
.c-header-revamp-component .results-container {
  transform: skew(17deg);
}
.c-header-revamp-component .results-container .autosuggest-list {
  margin-top: 30px;
  background-color: #f8f8f8;
}

.c-header-revamp-component {
  /* < 1023px */
}
.c-header-revamp-component.desktop {
  display: block;
}
.c-header-revamp-component.mobile {
  display: none;
}
@media (min-width: 320px) and (max-width: 1023px) {
  .c-header-revamp-component.mobile {
    display: flex;
  }
  .c-header-revamp-component.desktop {
    display: none;
  }
}